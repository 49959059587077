import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@context365/button'
import { DatePicker, Input, Select } from '@context365/forms'
import { Save } from '@context365/icons'
import { Modal } from '@context365/modals/Modal'
import { message } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import map from 'lodash/map'
import { Link, useHistory } from 'react-router-dom'
import {
  checkForDupeCompanies,
  createCompany,
  getTierOptions,
} from '../../actions/company'
import { getCountries } from '../../actions/fundList'
import {
  CATEGORY_ALLOCATOR,
  CATEGORY_MANAGER,
  CATEGORY_SERVICE_PROVIDER,
} from '../../constants/types/categories'
import { TIER_PRO } from '../../constants/types/tiers'

const NewCompanyForm = ({ categoryId }) => {
  const [company, setCompany] = useState({
    categoryId,
  })

  const history = useHistory()

  const [countries, setCountries] = useState([])
  const [tiers, setTiers] = useState([])
  const [dupeCompanies, setDupeCompanies] = useState([])
  const [dupeModalVisible, setDupeModalVisible] = useState(false)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    getCountries().then((res) => setCountries(res.data))
  }, [])

  useEffect(() => {
    getTierOptions().then((res) => setTiers(res.data))
  }, [])

  const handleChange = useCallback(
    (key, value) => {
      setCompany({ ...company, [key]: value })
    },
    [company]
  )

  const handleSave = () => {
    if (isEmpty(company.name) || isNil(company.categoryId)) {
      setTouched(true)
    } else {
      checkForDupeCompanies(company.name)
        .then((res) => {
          if (isEmpty(res.data)) {
            createCompany(company)
              .then(() => {
                message.success('Company created successfully')
                history.push('/')
              })
              .catch(() => {
                message.error('An error ocurred while creating this company')
              })
          } else {
            setDupeCompanies(res.data)
            setDupeModalVisible(true)
          }
        })
        .catch(() => {
          message.error('An error ocurred while creating this company')
        })
    }
  }

  const handleConfirm = () => {
    createCompany(company)
      .then(() => {
        setDupeModalVisible(false)
        setDupeCompanies([])
        message.success('Company created successfully')
        history.push('/')
      })
      .catch(() =>
        message.error('An error ocurred while creating this company')
      )
  }

  return (
    <>
      <div className="bg-white p-4">
        <div className="type-header-md mb-4">Create New Company</div>
        <div className="grid grid-cols-4 gap-4">
          <Input
            className="col-span-2"
            required
            label="Company Name"
            value={company.name}
            errorMessage={touched && isEmpty(company.name) ? 'Required' : null}
            onChange={(e) => handleChange('name', e.target.value)}
          />
          <Select
            className="col-start-1"
            label="Category"
            placeholder="Select a category"
            required
            value={company.categoryId}
            onChange={(e) => handleChange('categoryId', e)}
            errorMessage={
              touched && isNil(company.categoryId) ? 'Required' : null
            }
          >
            <Select.Option value={CATEGORY_MANAGER}>Manager</Select.Option>
            <Select.Option value={CATEGORY_ALLOCATOR}>Allocator</Select.Option>
            <Select.Option value={CATEGORY_SERVICE_PROVIDER}>
              Service Provider
            </Select.Option>
          </Select>
          {company.categoryId === CATEGORY_MANAGER && (
            <>
              <Select
                className="col-start-1"
                label="Tier"
                placeholder="Select a tier"
                required
                value={company.tierId}
                onChange={(e) => handleChange('tierId', e)}
              >
                {map(tiers, (t) => (
                  <Select.Option value={t.id}>{t.name}</Select.Option>
                ))}
              </Select>
              {company.tierId === TIER_PRO && (
                <>
                  <DatePicker
                  label="Pro Expiring At"
                  value={
                    company.proExpiringAt
                      ? new Date(company.proExpiringAt)
                      : null
                  }
                  onChange={(d) =>
                    handleChange('proExpiringAt', d.toDateString())
                  }
                />
                </>
              )}
              <DatePicker
                label="Subscriber Start Date"
                value={
                  company.subscriberStartDate
                    ? new Date(company.subscriberStartDate)
                    : null
                }
                onChange={(d) =>
                  handleChange('subscriberStartDate', d.toDateString())
                }
              />
              <DatePicker
                label="Subscriber End Date"
                value={
                  company.subscriberEndDate
                    ? new Date(company.subscriberEndDate)
                    : null
                }
                onChange={(d) =>
                  handleChange('subscriberEndDate', d.toDateString())
                }
              />
            </>
          )}
          <Input
            className="col-start-1"
            label="Street Address"
            value={company.address1}
            onChange={(e) => handleChange('address1', e.target.value)}
          />
          <Input
            label="Address 2"
            value={company.address2}
            onChange={(e) => handleChange('address2', e.target.value)}
          />
          <Input
            label="Address 3"
            value={company.address3}
            onChange={(e) => handleChange('address3', e.target.value)}
          />
          <Select.AutoComplete
            className="col-start-1"
            label="Country"
            placeholder="Select coutntry"
            value={company.country}
            onChange={(e) => handleChange('country', e)}
          >
            {({ inputValue }) =>
              inputValue.length > 0
                ? map(
                    countries.filter((a) =>
                      a.name.toLowerCase().includes(inputValue.toLowerCase())
                    ),
                    (c) => (
                      <Select.Option value={c.name}>{c.name}</Select.Option>
                    )
                  )
                : 'Begin typing to search'
            }
          </Select.AutoComplete>
          <Input
            label="City"
            value={company.city}
            onChange={(e) => handleChange('city', e.target.value)}
          />
          <Input
            label="State/Province"
            value={company.state}
            onChange={(e) => handleChange('state', e.target.value)}
          />
          <Input
            label="Zipcode"
            value={company.postalCode}
            onChange={(e) => handleChange('postalCode', e.target.value)}
          />
        </div>
        <div className="mt-8 flex justify-between">
          <Button as={Link} to={`/`} variant="filled" status="error">
            Cancel
          </Button>
          <Button iconLeft={<Save />} onClick={handleSave} variant="filled">
            Save
          </Button>
        </div>
      </div>
      <Modal
        visible={dupeModalVisible}
        title="Possible Duplicates Found"
        onDismiss={() => setDupeModalVisible(false)}
        footerLeft={
          <Button
            onClick={() => setDupeModalVisible(false)}
            status="error"
            variant="filled"
          >
            Cancel
          </Button>
        }
        footerRight={
          <Button onClick={handleConfirm} variant="filled">
            Create Company
          </Button>
        }
      >
        <div>
          Please review the following companies to ensure you are not creating a
          duplicate:
        </div>
        {map(dupeCompanies, (c) => (
          <div className="type-body-semibold-sm mt-2">{`${c.name} - ${c.category.categoryName}`}</div>
        ))}
      </Modal>
    </>
  )
}

NewCompanyForm.propTypes = {
  categoryId: PropTypes.number.isRequired,
}

export default NewCompanyForm
