import {
  faCheck,
  faTimes,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Modal, Tag, Tooltip } from 'antd'
import { isNil, map, sum } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { getCompanyCategories } from '../actions/company'
import { getContentEntryCategories } from '../actions/contentEntries'
import { getUpcomingEvents } from '../actions/events'
import {
  getBroadStrategies,
  getCategories,
  getCountries,
  getSubStrategies,
  getTiers,
} from '../actions/fundList'
import { deleteMandate, setExclusive } from '../actions/mandates'
import ActionButton from '../components/ActionButton/ActionButton'
import {
  CCTableActionCell,
  CCTableCheckCell,
  CCTableContactLinkCell,
  CCTableCurrencyCell,
  CCTableDateTimeCell,
  CCTableLinkCell,
  CCTableNumberCell,
  CCTablePercentageCell,
  CCTableProfilePicsCell,
  CCTableReverseCheckCell,
  CCTableTagGroupCell,
} from '../components/ContextTable/ContextTable'
import RatingDisplay from '../components/RatingDisplay/RatingDisplay'
import { getMenuItems } from './menuItems'
import {
  adminUserMenus,
  allocatorsMenus,
  announcementListMenus,
  cfnMenus,
  contentEntryMenus,
  dealsListMenus,
  fundMenus,
  mediaMenus,
  onlineAllocatorCompaniesMenus,
  onlineCFNCompaniesMenus,
  onlineFundMenus,
  onlineSPCompaniesMenus,
  serviceProviderMenus,
  sponsorContentEventMenus,
  sponsorContentMenus,
  summitAllocatorAttendeesMenu,
  summitAllocatorCompaniesMenus,
  summitCFNAttendeesMenu,
  summitFundAttendeesMenu,
  summitFundMenus,
  summitServiceProviderCompaniesMenus,
  userListMenus,
} from './menus'
import { CATEGORY_ALLOCATOR, CATEGORY_MEDIA, CATEGORY_SERVICE_PROVIDER, CATEGORY_MANAGER, CATEGORY_CFN } from '../constants/types/categories'

const { confirm } = Modal

export const getAllocatorDefaultColumns = (refresh) => [
  {
    title: 'ALLOCATION COMPANY',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    onFilter: (value, record) => record.company.name.indexOf(value) === 0,
    render: (text, b) => (
      <Link to={`/companies/allocator/${b.company.companyId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataIndex: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },

  {
    title: 'UPCOMING MEETINGS',
    dataIndex: 'upcomingMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'PORTFOLIO SIZE',
    dataIndex: 'portfolioSize',
    align: 'center',
    cellType: CCTableCurrencyCell,
    sorter: true,
    step: 10000,
  },

  {
    title: 'COUNTRY',
    dataIndex: 'company.country',
    filterMethod: getCountries,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    sorter: true,
    align: 'center',
  },

  {
    title: 'UPCOMING EVENT',
    align: 'center',
    cellType: CCTableTagGroupCell,
    key: 'upcomingEvent.name',
    itemsMethod: (x) =>
      isNil(x.upcomingEvent) ? [] : x.upcomingEvent.name.split(','),
    tagGroupColor: '#2FC5D2',
    filterMethod: getUpcomingEvents,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    sorter: false,
  },

  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={allocatorsMenus(tag)}
        companyType="allocators"
        refresh={refresh}
      />
    ),
  },
]

export const getAllocatorCompanyEventColumns = [
  {
    title: 'Event',
    dataIndex: 'eventName',
    sorter: true,
    fixed: 'left',
    width: '250px',
    onFilter: (value, record) => record.company.name.indexOf(value) === 0,
    render: (text, b) => (
      <Link to={`/summits/${b.eventId}/attendees/allocators/${b.company.name}`}>
        {text}
      </Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Attendees',
    key: 'attendes',
    dataIndex: 'attendes',
    align: 'center',
  },
  {
    title: 'Seats',
    key: 'suite',
    dataIndex: 'suite',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'averageRatingReceived',
    sorter: true,
    step: 10000,
    align: 'center',
    render: (tag) => (
      <RatingDisplay WouldMeetAgain={null} Rating={tag} Comment="" />
    ),
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={summitAllocatorCompaniesMenus(tag)}
        userType="allocator"
      />
    ),
  },
]

export const getFundCompanyEventColumns = [
  {
    title: 'Event',
    dataIndex: 'eventName',
    sorter: true,
    fixed: 'left',
    width: '250px',
    onFilter: (value, record) => record.company.name.indexOf(value) === 0,
    render: (text, b) => (
      // <Link to={`/schedule/${b.companyEventId}`}>{text}</Link>
      <Link to={`/summits/${b.eventId}/attendees/funds/${b.company.name}`}>
        {text}
      </Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Attendees',
    key: 'attendes',
    dataIndex: 'attendes',
    align: 'center',
  },
  {
    title: 'Seats',
    key: 'suite',
    dataIndex: 'suite',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'ratingsReceived',
    sorter: false,
    step: 10000,
    align: 'center',
    render: (tag) => (
      <RatingDisplay
        WouldMeetAgain={null}
        Rating={tag}
        Comment=""
        isFundsScreen={true}
      />
    ),
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton tag={tag} menus={summitFundMenus(tag)} userType="manager" />
    ),
  },
]

export const getServiceProviderCompanyEventColumns = [
  {
    title: 'Event',
    dataIndex: 'eventName',
    sorter: true,
    fixed: 'left',
    width: '250px',
    //render: text => <a href="#">{text}</a>,
    render: (text, b) => (
      <Link to={`/schedule/sp/${b.companyEventId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Attendees',
    key: 'attendes',
    dataIndex: 'attendes',
    align: 'center',
  },
  {
    title: 'Seats',
    key: 'suite',
    dataIndex: 'suite',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={summitServiceProviderCompaniesMenus(tag)}
        userType="serviceprovider"
      />
    ),
  },
]

export const getMediaCompanyEventColumns = [
  {
    title: 'Event',
    dataIndex: 'eventName',
    fixed: 'left',
    width: '250px',
    //render: text => <a href="#">{text}</a>,
    render: (text, b) => (
      <Link to={`/schedule/${b.companyEventId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Attendees',
    key: 'attendes',
    dataIndex: 'attendes',
    align: 'center',
  },
  {
    title: 'Seats',
    key: 'suite',
    dataIndex: 'suite',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={summitServiceProviderCompaniesMenus(tag)}
        userType="media"
      />
    ),
  },
]

export const getFundCompanyColumns = (refresh) => [
  {
    title: 'FUND COMPANY',
    fixed: 'left',
    sorter: true,
    dataIndex: 'company.name',
    width: '250px',
    render: (text, b) => (
      <Link to={`/companies/fund/${b.companyId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataIndex: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },
  {
    title: 'UPCOMING MEETINGS',
    dataIndex: 'upcomingMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'NUMBER OF FUNDS',
    dataIndex: 'totalFunds',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'FIRM AUM',
    dataIndex: 'firmAum',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableCurrencyCell,
  },
  {
    title: 'COUNTRY',
    dataIndex: 'company.country',
    key: 'company.country',
    filterMethod: getCountries,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    render: (text, b) => <div>{b.country}</div>,
    sorter: true,
    align: 'center',
  },
  {
    title: 'UPCOMING EVENT',
    cellType: CCTableTagGroupCell,
    key: 'upcomingEvent.name',
    itemsMethod: (x) =>
      isNil(x.upcomingEvent) ? [] : x.upcomingEvent.name.split(','),
    tagGroupColor: '#2FC5D2',
    filterMethod: getUpcomingEvents,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
  },
  {
    title: 'ACTION',
    width: '100px',
    align: 'center',
    fixed: 'right',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={fundMenus(tag)}
        companyType="fundmanagers"
        refresh={refresh}
      />
    ),
  },
  // { title: "ANNUALIZED VOLATILITY", sorter: true, dataIndex: 'AnnualizedVolatility', key: 'AnnualizedVolatility' }
]

export const getServiceProviderDefaultColumns = (refresh) => [
  {
    title: 'SERVICE PROVIdER',
    width: '250px',
    fixed: 'left',
    sorter: true,
    dataIndex: 'company.name',
    render: (text, b) => (
      <Link to={`/companies/serviceprovider/${b.companyId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataIndex: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },
  {
    title: 'UPCOMING MEETINGS',
    dataIndex: 'upcomingMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'TYPE',
    cellType: CCTableTagGroupCell,
    key: 'Type',
    itemsMethod: (x) => (isNil(x.type) ? [] : x.type.split(',')),
    tagGroupColor: '#6A48C6',
    //filterMethod: getServiceProviderCategories,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    filterMethod: getCategories,
    sorter: false,
    align: 'center',
  },

  {
    title: 'COUNTRY',
    dataIndex: 'company.country',
    key: 'company.country',
    filterMethod: getCountries,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    render: (text, b) => <div>{b.country}</div>,
    sorter: true,
    align: 'center',
  },
  {
    title: 'UPCOMING EVENT',
    cellType: CCTableTagGroupCell,
    key: 'upcomingEvent.name',
    itemsMethod: (x) =>
      isNil(x.upcomingEvent) ? [] : x.upcomingEvent.name.split(','),
    tagGroupColor: '#2FC5D2',
    filterMethod: getUpcomingEvents,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    sorter: false,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={serviceProviderMenus(tag)}
        companyType="serviceproviders"
        refresh={refresh}
      />
    ),
  },
]

export const getMediaDefaultColumns = (refresh) => [
  {
    title: 'MEDIA',
    dataIndex: 'companyName',
    width: '250px',
    fixed: 'left',
    render: (text, b) => (
      <Link to={`/companies/media/${b.companyId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return <span>{value === null ? '' : moment(value).format(format)}</span>
    },
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },
  {
    title: 'UPCOMING MEETINGS',
    dataIndex: 'upcomingMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    sorter: (a, b) => a.upcomingMeetings - b.upcomingMeetings,
  },
  {
    title: 'TYPE',
    dataIndex: 'type',
    sorter: true,
    align: 'center',
    itemsMethod: (x) => isNil(x.type),
    sorter: (a, b) => a.type.localeCompare(b.name),
    render: (tag) => (
      <span>
        {tag !== undefined && (
          <Tag color={'#6A48C6'} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        )}
      </span>
    ),
  },
  {
    title: 'COUNTRY',
    dataIndex: 'country',
    sorter: true,
    align: 'center',
    sorter: (a, b) => {
      a = a.country || 'z'
      b = b.country || 'z'
      return a.localeCompare(b)
    },
  },
  {
    title: 'UPCOMING EVENT',
    dataIndex: 'upcomingEvent.name',
    align: 'center',
    itemsMethod: (x) => isNil(x.upcomingEvent),
    sorter: (a, b) => {
      a = a.upcomingEvent || { name: '' }
      b = b.upcomingEvent || { name: '' }
      return a.name.localeCompare(b.name)
    },
    render: (tag) => (
      <span>
        {tag !== undefined && (
          <Tag color={'#2FC5D2'} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        )}
      </span>
    ),
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={mediaMenus(tag)}
        companyType="mediaprofiles"
        refresh={refresh}
      />
    ),
  },
]

export const getCFNDefaultColumns = (refresh) => [
  {
    title: 'COMPANY',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    onFilter: (value, record) => record.company.name.indexOf(value) === 0,
    render: (text, b) => (
      <Link to={`/companies/cfn/${b.company.companyId}`}>{text}</Link>
    ),
  },
  {
    title: 'MEMBERS',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataIndex: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },

  {
    title: 'UPCOMING MEETINGS',
    dataIndex: 'upcomingMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },

  {
    title: 'COUNTRY',
    dataIndex: 'company.country',
    filterMethod: getCountries,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    sorter: true,
    align: 'center',
  },

  {
    title: 'FAMILY NAME',
    dataIndex: 'familyName',
    sorter: true,
    align: 'center',

    width: '200px',
  },

  {
    title: 'FAMILY GENERATION',
    dataIndex: 'familyGeneration',
    sorter: true,
    align: 'center',

    width: '200px',
  },

  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={cfnMenus(tag)}
        companyType="cfn"
        refresh={refresh}
      />
    ),
  },
]

export const getAllCompaniesDefaultColumns = (refresh) => [
  {
    title: 'ALL COMPANIES',
    width: '250px',
    fixed: 'left',
    sorter: true,
    dataIndex: 'company.name',
    render: (text, b) => {
      if(b.category.categoryId == CATEGORY_MANAGER)
      {
        return (<Link to={`/companies/fund/${b.companyId}`}>{text}</Link>)
      }
      else if (b.category.categoryId == CATEGORY_ALLOCATOR)
      {
        return (<Link to={`/companies/allocator/${b.companyId}`}>{text}</Link>)
      }
      else if (b.category.categoryId == CATEGORY_SERVICE_PROVIDER)
      {
        return (<Link to={`/companies/serviceprovider/${b.companyId}`}>{text}</Link>)
      }
      else if (b.category.categoryId == CATEGORY_MEDIA)
      {
        return (<Link to={`/companies/media/${b.companyId}`}>{text}</Link>)
      }
      else if (b.category.categoryId == CATEGORY_CFN)
      {
        return (<Link to={`/companies/cfn/${b.company.companyId}`}>{text}</Link>)
      }
    },
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'CATEGORY',
    dataIndex: 'company.category.categoryName',
    key: 'company.category.categoryName',
    render: (text, b) => <div>{b.category.categoryName}</div>,
    sorter: false,
    align: 'center',
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataIndex: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },
  {
    title: 'UPCOMING MEETINGS',
    dataIndex: 'upcomingMeetings',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'COUNTRY',
    dataIndex: 'company.country',
    key: 'company.country',
    filterMethod: getCountries,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    render: (text, b) => <div>{b.country}</div>,
    sorter: true,
    align: 'center',
  },
  {
    title: 'UPCOMING EVENT',
    cellType: CCTableTagGroupCell,
    key: 'upcomingEvent.name',
    itemsMethod: (x) =>
      isNil(x.upcomingEvent) ? [] : x.upcomingEvent.name.split(','),
    tagGroupColor: '#2FC5D2',
    filterMethod: getUpcomingEvents,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
    sorter: false,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag,b) => {
        if(b.category.categoryId == CATEGORY_MANAGER)
        {
          return (<ActionButton
            tag={tag}
            menus={fundMenus(tag)}
            companyType="fundmanagers"
            refresh={refresh}
          />)
        }
        else if(b.category.categoryId == CATEGORY_ALLOCATOR)
        {
          return ( <ActionButton
            tag={tag}
            menus={allocatorsMenus(tag)}
            companyType="allocators"
            refresh={refresh}
          />)
        }
        else if(b.category.categoryId == CATEGORY_SERVICE_PROVIDER)
        {
          return (<ActionButton
            tag={tag}
            menus={serviceProviderMenus(tag)}
            companyType="serviceproviders"
            refresh={refresh}
          />)
        }
        else if(b.category.categoryId == CATEGORY_MEDIA)
        {
          return (<ActionButton
            tag={tag}
            menus={mediaMenus(tag)}
            companyType="mediaprofiles"
            refresh={refresh}
          />)
        }
        else if(b.category.categoryId == CATEGORY_CFN)
        {
          return (<ActionButton
            tag={tag}
            menus={cfnMenus(tag)}
            companyType="cfn"
            refresh={refresh}
          />)
        }
    },
  },
]

export const getUserColumns = (refresh) => [
  {
    title: 'USER',
    fixed: 'left',
    dataIndex: 'firstname',
    sorter: true,
    width: '250px',
    render: (text, b) => (
      <>
        <Avatar size="28px" src={b.imageUrl} style={{ marginRight: '8px' }}>
          {b.firstName.substring(0, 1) + b.lastName.substring(0, 1)}
        </Avatar>
        <Link to={`/users/${b.contactId}`}>
          {`${b.firstName} ${b.lastName}`}
        </Link>
      </>
    ),
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    sorter: true,
    render: (text, b) => (
        <span>{b.email}</span>
    ),
  },
  {
    title: 'TITLE',
    dataIndex: 'jobTitle',
    sorter: true,
    render: (text, b) => (
        <span>{b.jobTitle}</span>
    ),
  },
  {
    title: 'COMPANY',
    dataIndex: 'companies.name',
    //sorter: true,
    render: (text, b) =>
      b.companies.map((comp, index) => {
        if (index < b.companies.length - 1) {
          return <span>{comp.name}, </span>
        } else {
          return <span>{comp.name}</span>
        }
      }),
    // sorter: (a, b) => {
    //   var aComps = "";
    //   var bComps = "";
    //   a.companies.map((comp, index) => {
    //     if (index < a.companies.length - 1) {
    //       aComps = aComps + comp.name + ", ";
    //     } else {
    //       aComps = aComps + comp.name;
    //     }
    //   });
    //   b.companies.map((comp, index) => {
    //     if (index < b.companies.length - 1) {
    //       bComps = bComps + comp.name + ", ";
    //     } else {
    //       bComps = bComps + comp.name;
    //     }
    //   });
    //   return aComps.localeCompare(bComps);
    // }
  },
  {
    title: 'ROLE',
    dataIndex: 'companies.category',
    key: 'companies.category',
    // sorter: true,
    align: 'center',
    filterMethod: getCompanyCategories,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    render: (text, b) => {
      const roles = []
      return b.companies.map((comp, index) => {
        if (
          (index === 0 || b.companies.length === 1) &&
          roles.indexOf(comp.category) < 0
        ) {
          roles.push(comp.category)
          return <span>{comp.category}</span>
        } else if (b.companies.length > 1 && roles.indexOf(comp.category) < 0) {
          roles.push(comp.category)
          return <span>, {comp.category}</span>
        }
      })
    },
    // sorter: (a, b) => {
    //   var aRoles = "";
    //   var bRoles = "";
    //   a.companies.map((comp, index) => {
    //     if (index < a.companies.length - 1) {
    //       aRoles = aRoles + comp.category + ", ";
    //     } else {
    //       aRoles = aRoles + comp.category;
    //     }
    //   });
    //   b.companies.map((comp, index) => {
    //     if (index < b.companies.length - 1) {
    //       bRoles = bRoles + comp.category + ", ";
    //     } else {
    //       bRoles = bRoles + comp.category;
    //     }
    //   });
    //   return aRoles.localeCompare(bRoles);
    // }
  },
  {
    title: 'REGISTER DATE',
    key: 'createdAt',
    dataValue: 'registeredDate',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {value === null || value.registeredDate === null
            ? ''
            : moment(value.registeredDate).format(format)}
        </span>
      )
    },
  },
  // {
  //   title: 'COUNTRY',
  //   dataIndex: 'country',
  //   align: 'center',
  //   sorter: true,
  //   render: (text, b) => <span>{b.country}</span>,
  //   sorter: (a, b) => {
  //     let { country } = a
  //     if (isNil(country)) country = ''
  //     return country.localeCompare(b.country)
  //   },
  // },
  // {
  //   title: 'UPCOMING EVENT',
  //   align: 'center',
  //   cellType: CCTableTagGroupCell,
  //   key: 'upcomingEvent.name',
  //   itemsMethod: (x) =>
  //     isNil(x.upcomingEvent) ? [] : x.upcomingEvent.name.split(','),
  //   tagGroupColor: '#2FC5D2',
  //   sorter: false,
  //   // filterMethod: getUpcomingEvents,
  //   // filterMappingMethod: x => {
  //   //   console.log("x", x);
  //   //   return { value: x, text: x };
  //   // }
  // },
  {
    title: 'ACTION',
    width: '100px',
    align: 'center',
    fixed: 'right',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={userListMenus(tag)}
        companyType="user"
        refresh={refresh}
      />
    ),
  },
]

export const getPastSummits = [
  {
    title: 'EVENT NAME',
    fixed: 'left',
    dataIndex: 'name',
    width: '250px',
    sorter: false,
    render: (text, b) => (
      <Link to={`/summits/${b.eventId}/attendees/allocators`}>{text}</Link>
    ),
  },
  {
    title: 'DATE',
    dataIndex: 'startDate',
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (a, b) => {
      const format1 = 'MMM DD-'
      const format2 = 'DD, YYYY'
      return (
        <span>
          {b.startDate === null || b.endDate === null
            ? ''
            : moment.utc(b.startDate, moment.ISO_860).local().format(format1) +
              moment.utc(b.endDate, moment.ISO_860).local().format(format2)}
        </span>
      )
    },
    sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
  },
  {
    title: 'ALLOCATORS',
    dataIndex: 'allocators',
    sorter: true,
    step: 10000,
    align: 'center',
    sorter: (a, b) => a.allocators - b.allocators,
  },
  // {
  //   title: "ALLOCATOR CONTACTS",
  //   dataIndex: "allocatorContacts",
  //   sorter: true,
  //   step: 10000,
  //   align: "center",
  //   sorter: (a, b) => a.allocatorContacts - b.allocatorContacts,
  // },
  {
    title: 'FUND MANAGERS',
    dataIndex: 'fundManagers',
    sorter: true,
    step: 10000,
    align: 'center',
    sorter: (a, b) => a.fundManagers - b.fundManagers,
  },
  {
    title: 'MEDIA',
    dataIndex: 'media',
    sorter: true,
    align: 'center',
    sorter: (a, b) => a.media - b.media,
  },
  {
    title: 'SERVICE PROVIDERS',
    dataIndex: 'serviceProviders',
    sorter: true,
    align: 'center',
    sorter: (a, b) => a.serviceProviders - b.serviceProviders,
  },
  {
    title: 'CONFIRMED MEETINGS',
    dataIndex: 'confirmedMeetings',
    align: 'center',
  },
  {
    title: 'TOTAL',
    dataIndex: 'total',
    sorter: true,
    align: 'center',
    render: (text) => <strong>{text}</strong>,
    sorter: (a, b) => a.total - b.total,
  },
]

export const getFundColumnsForServiceProviders = (
  requestMeeting,
  markAsFavourite,
  sendMessage,
  viewDetails
) => [
  {
    title: 'FUND NAME',
    cellType: CCTableLinkCell,
    fixed: 'left',
    dataIndex: 'name',
    sorter: true,
    fixed: 'left',
    width: '250px',
  },
  {
    title: 'CONTACTS',
    cellType: CCTableProfilePicsCell,
    dataIndex: 'contacts',
    fixed: 'left',
    width: '120px',
  },
  {
    title: 'ACTIONS',
    cellType: CCTableActionCell,
    menuItemsMethod: getMenuItems.bind(
      null,
      requestMeeting,
      markAsFavourite,
      sendMessage,
      viewDetails
    ),
    fixed: 'left',
    width: '100px',
    align: 'center',
  },
  {
    title: 'COMPANY NAME',
    dataIndex: 'company.name',
    sorter: true,
  },
  {
    title: 'BROAD STRATEGY GROUP',
    cellType: CCTableTagGroupCell,
    itemsMethod: (x) =>
      isNil(x.broadStrategy) ? [] : x.broadStrategy.split(','),
    key: 'broadStrategy',
    sorter: false,
    align: 'center',
    tagGroupColor: 'rgba(45, 129, 168)',
    filterMappingMethod: (x) => ({ value: x, text: x }),
  },
  {
    title: 'SUB STRATEGY',
    cellType: CCTableTagGroupCell,
    itemsMethod: (x) => (isNil(x.subStrategy) ? [] : x.subStrategy.split(',')),
    key: 'subStrategy',
    sorter: false,
    align: 'center',
    tagGroupColor: 'rgba(106, 72, 198)',
    filterMethod: getSubStrategies,
    filterMappingMethod: (x) => ({ value: x, text: x }),
  },
  {
    title: 'FUND AUM',
    dataIndex: 'fundAum',
    sorter: true,
    cellType: CCTableCurrencyCell,
    step: 10000,
  },
  {
    title: 'FIRM AUM',
    dataIndex: 'firmAum',
    sorter: true,
    cellType: CCTableCurrencyCell,
    step: 10000,
  },
  {
    title: 'LOCATION',
    render: (x) => <span>{`${x.company.city}, ${x.company.country}`}</span>,
  },
  {
    title: 'PRIME BROKER',
    dataIndex: 'primeBroker1',
    sorter: true,
  },
  {
    title: 'AUDITOR',
    dataIndex: 'auditor',
    sorter: true,
  },
  {
    title: 'LAW FIRM',
    dataIndex: 'exeternallegalCounsel',
    sorter: true,
  },
  {
    title: 'FUND ADMINISTRATOR',
    dataIndex: 'fundAdministartor',
    sorter: true,
  },
]

export const getColumnsForMedia = (
  requestMeeting,
  markAsFavourite,
  sendMessage,
  viewDetails
) => [
  {
    title: 'COMPANY NAME',
    dataIndex: 'company.name',
    fixed: 'left',
    cellType: CCTableLinkCell,
    sorter: true,
    fixed: 'left',
    width: '250px',
  },
  {
    title: 'CONTACTS',
    cellType: CCTableProfilePicsCell,
    dataIndex: 'contacts',
    fixed: 'left',
    width: '120px',
  },
  {
    title: 'ACTIONS',
    cellType: CCTableActionCell,
    menuItemsMethod: getMenuItems.bind(
      null,
      requestMeeting,
      markAsFavourite,
      sendMessage,
      viewDetails
    ),
    fixed: 'left',
    width: '100px',
    align: 'center',
  },
  {
    title: 'COMPANY DESCRIPTION',
    dataIndex: 'company.companyDescription',
    sorter: true,
  },
]

export const getServiceProviderAttendeeDefaultColumns = (
  isPast,
  refresh,
  isVirtual
) => [
  {
    title: 'Service Provider',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    //render: text => <a href="#">{text}</a>,
    render: (text, b) => (
      <Link to={`/schedule/sp/${b.eventCompanyBoothId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Location',
    dataIndex: 'boothId',
    sorter: true,
    fixed: 'left',
  },
  {
    title: 'Partic.No',
    dataIndex: 'participantsNumber',
    width: '100px',
    fixed: 'left',
    align: 'left',
    sorter: true,
  },

  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => {
      tag.isVirtualSummit = isVirtual
      return (
        <ActionButton
          tag={tag}
          refresh={refresh}
          menus={summitServiceProviderCompaniesMenus(tag)}
          userType="serviceprovider"
        />
      )
    },
  },
]

export const getMediaAttendeeDefaultColumns = () => [
  {
    title: 'Media',
    dataIndex: 'company.name',
    fixed: 'left',
    width: '250px',
    //render: text => <a href="#">{text}</a>,
    render: (text, b) => (
      <Link to={`/schedule/${b.companyEventId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Attendees',
    key: 'attendes',
    dataIndex: 'attendes',
    align: 'center',
  },
  {
    title: 'Seats',
    key: 'suite',
    dataIndex: 'suite',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={summitServiceProviderCompaniesMenus(tag)}
        userType="media"
      />
    ),
  },
]

export const getSummitAllocatorAttendeeDefaultColumns = (
  isPast,
  refresh,
  isVirtual
) => [
  {
    title: 'Allocation Company',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/schedule/allocator/${b.eventParticipantId}`}>{text}</Link>
    ),
    // ,
    // sorter: (a, b) => a.company.name.localeCompare(b.company.name)
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  // {
  //   title: 'Partic.No',
  //   dataIndex: 'participantsNumber',
  //   width: '100px',
  //   fixed: 'left',
  //   align: 'left',
  //   sorter: true,
  // },
  // {
  //   title: "Attendees",
  //   key: "attendes",
  //   dataIndex: "attendes",
  //   align: "center",
  // },
  // {
  //   title: "Suite",
  //   key: "suite",
  //   dataIndex: "suite",
  //   align: "center",
  // },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'averageRatingReceived',
    sorter: true,
    step: 10000,
    align: 'center',
    render: (tag) =>
      !isNil(tag) &&
      tag != 0 && (
        <RatingDisplay WouldMeetAgain={null} Rating={tag} Comment="" />
      ),
  },
  {
    title: 'Ratings Received Count',
    dataIndex: 'ratingsCount',
    sorter: true,
    step: 10000,
    align: 'center',
    render: (tag) => !isNil(tag) && tag != 0 && tag,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refresh}
        menus={summitAllocatorAttendeesMenu(tag, isVirtual)}
        userType="allocator"
      />
    ),
  },
]
export const getSummitCFNAttendeeDefaultColumns = (refresh, isVirtual) => [
  {
    title: 'Company Name',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/schedule/family-member/${b.eventParticipantId}`}>{text}</Link>
    ),
    // ,
    // sorter: (a, b) => a.company.name.localeCompare(b.company.name)
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Family Name',
    dataIndex: 'familyName',
    sorter: true,
    width: '250px',
    // ,
    // sorter: (a, b) => a.company.name.localeCompare(b.company.name)
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refresh}
        menus={summitCFNAttendeesMenu(tag)}
        userType="cfn"
      />
    ),
  },
]
export const getOnlineAllocatorAttendeeDefaultColumns = (refresh) => [
  {
    title: 'Allocation Company',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/online/allocator/${b.companyContactId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  // {
  //   title: "Attendees",
  //   key: "attendes",
  //   dataIndex: "attendes",
  //   align: "center",
  // },
  // {
  //   title: "Suite",
  //   key: "suite",
  //   dataIndex: "suite",
  //   align: "center",
  // },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'averageRatingReceived',
    sorter: true,
    step: 10000,
    align: 'center',
    render: (tag) =>
      !isNil(tag) &&
      tag != 0 && (
        <RatingDisplay WouldMeetAgain={null} Rating={tag} Comment="" />
      ),
  },
  {
    title: 'Ratings Received Count',
    dataIndex: 'ratingsCount',
    sorter: true,
    step: 10000,
    align: 'center',
    render: (tag) => !isNil(tag) && tag != 0 && tag,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refresh}
        menus={onlineAllocatorCompaniesMenus(tag)}
        userType="onlineAllocator"
      />
    ),
  },
]

export const getFundAttendeeDefaultColumns = () => [
  {
    title: 'Fund Name',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    //key: "company.name",
    width: '250px',
    //render: text => <a href="#">{text}</a>,
    render: (text, b) => (
      <Link to={`/schedule/${b.companyEventId}`}>{text}</Link>
    ),
    //sorter: (a, b) => a.company.name.localeCompare(b.company.name)
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Attendees',
    key: 'attendes',
    dataIndex: 'attendes',
    align: 'center',
  },
  {
    title: 'Seats',
    key: 'suite',
    dataIndex: 'suite',
    align: 'center',
  },
  {
    title: 'Request Sent',
    key: 'sentMeetings',
    dataIndex: 'sentMeetings',
    align: 'center',
    sorter: true,
    sorter: (a, b) => a.sentMeetings - b.sentMeetings,
  },
  {
    title: 'Request Received',
    key: 'receivedMeetings',
    dataIndex: 'receivedMeetings',
    align: 'center',
    sorter: true,
    sorter: (a, b) => a.receivedMeetings - b.receivedMeetings,
  },
  {
    title: 'Confirmed Meetings',
    key: 'confirmedMeetings',
    dataIndex: 'confirmedMeetings',
    align: 'center',
    sorter: true,
    sorter: (a, b) => a.confirmedMeetings - b.confirmedMeetings,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={summitFundAttendeesMenu(tag)}
        userType="fund"
      />
    ),
  },
]

export const getOnlineFundAttendeeDefaultColumns = (refresh) => [
  {
    title: 'Fund Name',
    dataIndex: 'fund.name',
    sorter: true,
    fixed: 'left',
    //key: "company.name",
    width: '250px',
    //render: text => <a href="#">{text}</a>,
    render: (text, b) => (
      <Link to={`/online/fund/${b.fund.fundId}`}>{text}</Link>
    ),
    //sorter: (a, b) => a.company.name.localeCompare(b.company.name)
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'TIER',
    dataIndex: 'tier',
    key: 'tier',
    filterMethod: getTiers,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    sorter: true,
    //fixed: "left",
    width: '100px',
  },
  {
    title: 'Request Sent',
    key: 'sentRequests',
    dataIndex: 'sentRequests',
    align: 'center',
    sorter: true,
    sorter: (a, b) => a.sentMeetings - b.sentMeetings,
  },
  {
    title: 'Request Received',
    key: 'receivedRequests',
    dataIndex: 'receivedRequests',
    align: 'center',
    sorter: true,
    sorter: (a, b) => a.receivedMeetings - b.receivedMeetings,
  },
  {
    title: 'Confirmed Meetings',
    key: 'confirmedRequests',
    dataIndex: 'confirmedRequests',
    align: 'center',
    sorter: true,
    sorter: (a, b) => a.confirmedMeetings - b.confirmedMeetings,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'ratingsReceived',
    sorter: false,
    step: 10000,
    align: 'center',
    render: (tag) => (
      <RatingDisplay
        WouldMeetAgain={null}
        Rating={tag}
        Comment=""
        isFundsScreen={true}
      />
    ),
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={onlineFundMenus(tag)}
        userType="onlineFundManager"
        refresh={refresh}
      />
    ),
  },
]

export const getOnlineSPAttendeeDefaultColumns = (refresh) => [
  {
    title: 'Company',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/online/sp/${b.company.companyId}`}>{text}</Link>
    ),
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refresh}
        menus={onlineSPCompaniesMenus(tag)}
        userType="onlineSP"
      />
    ),
  },
]

export const getOnlineCFNAttendeeDefaultColumns = (refresh) => [
  {
    title: 'Family Office',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/online/cfn/${b.company.companyId}`}>{text}</Link>
    ),
  },
  {
    title: 'Members',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    width: '130px',
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refresh}
        menus={onlineCFNCompaniesMenus(tag)}
        userType="onlineCFN"
      />
    ),
  },
]

export const getSummitFundAttendeeDefaultColumns = (
  isPast,
  refresh,
  isVirtual
) => [
  {
    title: 'Fund Name',
    dataIndex: 'fund.name',
    sorter: true,
    fixed: 'left',
    render: (text, b) => (
      <Link to={`/schedule/fund/${b.eventCompanyBoothFundId}`}>{text}</Link>
    ),
  },
  {
    title: 'Company Name',
    dataIndex: 'company.name',
    sorter: true,
    fixed: 'left',
  },
  {
    title: '',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    fixed: 'left',
    align: 'center',
  },
  {
    title: 'Location',
    dataIndex: 'boothId',
    sorter: true,
    fixed: 'left',
  },
  {
    title: 'Partic.No',
    dataIndex: 'participantsNumber',
    // fixed: 'left',
    align: 'center',
    step: 10000,
    sorter: true,
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    sorter: true,
    step: 10000,
    align: 'center',
    cellType: CCTableNumberCell,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'ratingsReceived',
    sorter: false,
    step: 10000,
    align: 'center',
    render: (tag) => (
      <RatingDisplay
        WouldMeetAgain={null}
        Rating={tag}
        Comment=""
        isFundsScreen={true}
      />
    ),
  },
  {
    title: 'Broad Strategy',
    dataIndex: 'fund.broadStrategy',
    sorter: false,
    step: 10000,
    align: 'center',
  },
  {
    title: 'Sub Strategy',
    dataIndex: 'fund.subStrategy',
    sorter: false,
    step: 10000,
    align: 'center',
  },
  {
    title: 'Fund AUM',
    dataIndex: 'fund.fundAUM',
    cellType: CCTableCurrencyCell,
    sorter: false,
    step: 10000,
    align: 'center',
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    render: (tag) => {
      tag.isVirtualSummit = isVirtual
      return (
        <ActionButton
          tag={tag}
          refresh={refresh}
          menus={summitFundAttendeesMenu(tag, isVirtual)}
          userType="manager"
        />
      )
    },
  },
]

export const getSummitAllManagersDefaultColumns = (isPast, refresh) => [
  {
    title: 'Company Name',
    dataIndex: 'company.name',
    fixed: 'left',
    align: 'left',
    sorter: true,
  },
  {
    title: 'Contact Name',
    key: 'contacts',
    cellType: CCTableProfilePicsCell,
    fixed: 'left',
    align: 'center',
    sorter: false,
  },
  {
    title: 'Participants Number',
    dataIndex: 'participantsNumber',
    fixed: 'left',
    align: 'left',
    sorter: true,
  },
  {
    title: 'Added Funds',
    dataIndex: 'fundsAddedToEvent',
    fixed: 'left',
    align: 'left',
    sorter: true,
  },
  {
    title: 'Request Sent',
    dataIndex: 'sentRequests',
    fixed: 'left',
    align: 'left',
    sorter: true,
    cellType: CCTableNumberCell,
  },
  {
    title: 'Request Received',
    dataIndex: 'receivedRequests',
    fixed: 'left',
    align: 'left',
    sorter: true,
    cellType: CCTableNumberCell,
  },
  {
    title: 'Confirmed Meetings',
    dataIndex: 'confirmedRequests',
    fixed: 'left',
    align: 'left',
    sorter: true,
    cellType: CCTableNumberCell,
  },
  {
    title: 'Average Rating Received',
    dataIndex: 'ratingsReceived',
    fixed: 'left',
    align: 'left',
    render: (tag) => (
      <RatingDisplay
        WouldMeetAgain={null}
        Rating={tag}
        Comment=""
        isFundsScreen={true}
      />
    ),
  },
]

export const getContextContactColumns = (
  requestMeeting,
  markAsFavourite,
  sendMessage,
  viewDetails
) => [
  {
    title: 'CONTACT NAME',
    key: 'contact.firstName',
    fixed: 'left',
    itemsMethod: (x) => x.contact,
    cellType: CCTableContactLinkCell,
    fixed: 'left',
    width: '250px',
    sorter: true,
  },
  {
    key: '2',
    title: 'ACTIONS',
    cellType: CCTableActionCell,
    fixed: 'left',
    align: 'center',
    menuItemsMethod: getMenuItems.bind(
      null,
      requestMeeting,
      markAsFavourite,
      sendMessage,
      viewDetails
    ),
    width: '50px',
  },
  {
    key: '3',
    title: 'CONTACT TITLE',
    width: '250px',
    dataIndex: 'contact.jobTitle',
    sorter: true,
  },
  {
    key: '4',
    title: 'EMAIL',
    dataIndex: 'contact.email',
  },
  {
    key: '5',
    title: 'PHONE NUMBER',
    dataIndex: 'contact.mobilePhone',
  },
  {
    key: '6',
    title: 'COMPANY NAME',
    dataIndex: 'company.name',
    sorter: true,
  },
  {
    key: '7',
    title: 'COMPANY DESCRIPTION',
    dataIndex: 'company.companyDescription',
  },
]

export const getContextAttendeeColumns = (
  requestMeeting,
  markAsFavourite,
  sendMessage,
  viewDetails
) => [
  {
    title: 'COMPANY NAME',
    key: 'company.name',
    fixed: 'left',
    cellType: CCTableLinkCell,
    fixed: 'left',
    width: '250px',
    sorter: true,
  },
  {
    key: '2',
    title: 'ACTIONS',
    cellType: CCTableActionCell,
    fixed: 'left',
    align: 'center',
    menuItemsMethod: getMenuItems.bind(
      null,
      requestMeeting,
      markAsFavourite,
      sendMessage,
      viewDetails
    ),
    width: '50px',
  },
  {
    title: 'CONTACTS',
    width: '250px',
    fixed: 'left',
    align: 'center',
    cellType: CCTableProfilePicsCell,
    dataIndex: 'contacts',
    sorter: true,
  },
  {
    title: 'COMPANY DESCRIPTION',
    dataIndex: 'company.companyDescription',
  },
]

export const nameToColor = (name, lastName) => {
  const colorLimit = 50
  const minValue = colorLimit
  const maxValue = 255 - colorLimit

  let r = isNil(name) ? 0 : sum(name.split('').map((x) => x.charCodeAt()))
  let g = isNil(lastName)
    ? 0
    : sum(lastName.split('').map((x) => x.charCodeAt()))
  let b = r + g //isNil(name + lastName) ? 0 : sum((name + lastName).split('').map(x => x.charCodeAt()));

  r = (r % (maxValue - minValue)) + minValue
  g = (g % (maxValue - minValue)) + minValue
  b = (b % (maxValue - minValue)) + minValue

  //return `rgb(${r}, ${g}, ${b});`;
  const result = rgbToHex(r, g, b)
  return result
}

export const getCompanyEventReceivedMeetingRequests = [
  {
    title: 'MEMBER',
    dataIndex: 'member.contactName',
    fixed: 'left',
    sorter: false,
    fixed: 'left',
    width: '250px',
  },
  {
    title: 'COMPANY',
    key: 'company.companyName',
    width: '130px',
    fixed: 'left',
    align: 'center',
    sorter: false,
  },
  {
    title: 'CATEGORY',
    key: 'categoryName',
    dataIndex: 'categoryName',
    align: 'center',
    sorter: false,
  },
  {
    title: 'MEETING DATE AND TIME',
    key: 'meetingDateTime',
    dataIndex: 'meetingDateTime',
    align: 'center',
    sorter: false,
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
  },
]

export const getSponsorContentColumns = (refreshFunction) => [
  {
    title: 'COMPANY',
    key: 'companyName',
    dataIndex: 'companyName',
    fixed: 'left',
    align: 'left',
    sorter: false,
  },
  {
    title: 'TITLE',
    key: 'title',
    dataIndex: 'title',
    align: 'left',
    sorter: false,
  },
  {
    title: 'DATE',
    key: 'date',
    dataIndex: 'date',
    align: 'left',
    cellType: CCTableDateTimeCell,
    sorter: true,
    isFiltered: true,
    render: (tag) => (
      <span>
        {tag === null
          ? ''
          : moment.utc(tag.date).local().format('MMMM Do YYYY, h:mm A')}
      </span>
    ),
  },
  {
    title: 'ACTIVE',
    key: 'isActive',
    dataIndex: 'isActive',
    align: 'center',
    sorter: false,
    render: (value) =>
      value ? (
        <FontAwesomeIcon icon={faCheck} color={'#24c477'} />
      ) : (
        <FontAwesomeIcon icon={faTimes} color={'#d62b34'} />
      ),
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refreshFunction}
        menus={sponsorContentMenus(tag)}
      />
    ),
  },
]

export const getSponsorContentEventColumns = (refreshFunction) => [
  {
    title: 'COMPANY',
    key: 'companyName',
    dataIndex: 'companyName',
    fixed: 'left',
    align: 'left',
    sorter: false,
  },
  {
    title: 'TITLE',
    key: 'title',
    dataIndex: 'title',
    align: 'left',
    sorter: false,
  },
  {
    title: 'DATE',
    key: 'date',
    dataIndex: 'date',
    align: 'left',
    cellType: CCTableDateTimeCell,
    sorter: true,
    isFiltered: true,
    render: (tag) => (
      <span>
        {tag === null
          ? ''
          : moment.utc(tag.date).local().format('MMMM Do YYYY, h:mm A')}
      </span>
    ),
  },
  {
    title: 'ACTIVE',
    key: 'isActive',
    dataIndex: 'isActive',
    align: 'center',
    sorter: false,
    render: (value) =>
      value ? (
        <FontAwesomeIcon icon={faCheck} color={'#24c477'} />
      ) : (
        <FontAwesomeIcon icon={faTimes} color={'#d62b34'} />
      ),
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton
        tag={tag}
        refresh={refreshFunction}
        menus={sponsorContentEventMenus(tag)}
      />
    ),
  },
]

export const getLeadColumns = () => [
  {
    title: 'NAME',
    dataIndex: 'firstName',
    sorter: true,
    width: '200px',
    fixed: 'left',
    render: (text, b) => <span>{`${b.firstName} ${b.lastName}`}</span>,
  },
  {
    title: 'COMPANY',
    dataIndex: 'company',
    sorter: true,
  },
  {
    title: 'FUND',
    dataIndex: 'fundName',
    sorter: true,
  },
  {
    title: 'BROAD STRATEGY',
    cellType: CCTableTagGroupCell,
    itemsMethod: (x) =>
      isNil(x.broadStrategy) ? [] : x.broadStrategy.split(','),
    key: 'broadStrategy',
    sorter: false,
    filter: true,
    align: 'center',
    tagGroupColor: 'rgba(45, 129, 168)',
    filterMethod: getBroadStrategies,
    filterMappingMethod: (x) => ({ value: x, text: x }),
  },
  {
    title: 'SUB STRATEGY',
    cellType: CCTableTagGroupCell,
    itemsMethod: (x) => (isNil(x.subStrategy) ? [] : x.subStrategy.split(',')),
    key: 'subStrategy',
    sorter: false,
    align: 'center',
    tagGroupColor: 'rgba(106, 72, 198)',
    filterMethod: getSubStrategies,
    filterMappingMethod: (x) => ({ value: x, text: x }),
  },
  {
    title: 'FUND ATTRIBUTES',
    align: 'center',
    cellType: CCTableTagGroupCell,
    key: 'fundAttributes',
    itemsMethod: (x) => (isNil(x.fundAttributes) ? [] : x.fundAttributes),
    tagGroupColor: '#2FC5D2',
  },
  {
    title: 'SOURCE',
    dataIndex: 'source',
    sorter: true,
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    sorter: true,
  },
  {
    title: 'PHONE',
    dataIndex: 'phone',
    sorter: true,
  },
]

export const getAllocatorKpiBreakdownColumns = () => [
  {
    title: 'Fund Name',
    dataIndex: 'name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/companies/fund/${b.managerCompanyId}/3`} target="_blank">
        {text}
      </Link>
    ),
  },
  {
    title: 'Matchmaking Score',
    dataIndex: 'score',
    sorter: true,
    render: (value) => (
      <span>{isNil(value) ? '' : `${numeral(value).format('0.[0]%')} `}</span>
    ),
  },
]

export const getAllocatorKpiEngagementColumns = () => [
  {
    title: 'Fund Name',
    dataIndex: 'name',
    sorter: true,
    fixed: 'left',
    width: '250px',
    render: (text, b) => (
      <Link to={`/companies/fund/${b.managerCompanyId}/3`} target="_blank">
        {text}
      </Link>
    ),
  },
  {
    title: 'Matchmaking Score',
    dataIndex: 'score',
    sorter: true,
    render: (value) => (
      <span>{isNil(value) ? '' : `${numeral(value).format('0.[0]%')} `}</span>
    ),
  },
  {
    title: 'Last Action',
    key: 'lastActionDate',
    dataIndex: 'lastActionDate',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'center',
    render: (value) => {
      const format = 'MMM DD, YYYY'
      return (
        <span>
          {isNil(value) || isNil(value.lastActionDate)
            ? ''
            : moment(value.lastActionDate).format(format)}
        </span>
      )
    },
  },
]

export const getAllocatorMutualInterestColumns = () => [
  {
    title: (
      <Tooltip title="Count of allocator views of the fund profile">
        <span>Fund Profile Views</span>
      </Tooltip>
    ),
    dataIndex: 'fundProfileViews',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '200px',
    align: 'center',
  },
  {
    title: (
      <Tooltip title="Count of allocator views of fund marketing materials">
        <span>Marketing Views</span>
      </Tooltip>
    ),
    dataIndex: 'marketingViews',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '150px',
    align: 'center',
  },
  {
    title: (
      <Tooltip title="Count of allocator views of fund tearsheet">
        <span>Tearsheet Views</span>
      </Tooltip>
    ),
    dataIndex: 'tearsheetViews',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '150px',
    align: 'center',
  },
  {
    title: (
      <Tooltip title="Whether the allocator has favorited the fund">
        <span>Fund Favorited?</span>
      </Tooltip>
    ),
    dataIndex: 'isFavoriteFund',
    sorter: true,
    cellType: CCTableCheckCell,
    width: '150px',
    align: 'center',
  },
  {
    title: (
      <Tooltip title="Aggregate score of allocator interest towards a single fund">
        <span>Allocator Points</span>
      </Tooltip>
    ),
    dataIndex: 'allocatorScore',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '200px',
    align: 'center',
  },
  {
    title: (
      <Tooltip title="Aggregate score of a fund contact's interest towards the allocator">
        <span>Fund Points</span>
      </Tooltip>
    ),
    dataIndex: 'fundScore',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '125px',
    align: 'center',
    className: 'FundColumn',
  },
  {
    title: 'Manager Company',
    dataIndex: 'managerCompanyName',
    sorter: true,
    width: '250px',
    align: 'center',
    render: (text, b) => (
      <Link to={`/companies/fund/${b.managerCompanyId}`} target="_blank">
        {text}
      </Link>
    ),
    className: 'FundColumn',
  },
  {
    title: 'Fund',
    dataIndex: 'fundName',
    sorter: true,
    width: '250px',
    align: 'center',
    render: (text, b) => (
      <Link to={`/companies/fund/${b.managerCompanyId}/3`} target="_blank">
        {text}
      </Link>
    ),
    className: 'FundColumn',
  },
  {
    title: 'Broad Strategy',
    dataIndex: 'broadStrategy',
    sorter: true,
    width: '150px',
    align: 'center',
    filterMethod: getBroadStrategies,
    filterMappingMethod: (x) => ({ value: x, text: x }),
    className: 'FundColumn',
  },
  {
    title: (
      <Tooltip title="Fund contact that has demonstrated the most actions towards the allocator">
        <span>Contact</span>
      </Tooltip>
    ),
    dataIndex: 'contactName',
    sorter: true,
    width: '200px',
    align: 'center',
    className: 'FundColumn',
  },
  {
    title: (
      <Tooltip title="Count of fund contact views of the allocator profile">
        <span>Allocator Profile Views</span>
      </Tooltip>
    ),
    dataIndex: 'allocatorProfileViews',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '200px',
    align: 'center',
    className: 'FundColumn',
  },
  {
    title: (
      <Tooltip title="Whether the fund contact has favorited the allocator">
        <span>Allocator Favorited?</span>
      </Tooltip>
    ),
    dataIndex: 'isFavoriteAllocator',
    sorter: true,
    cellType: CCTableCheckCell,
    width: '150px',
    align: 'center',
    className: 'FundColumn',
  },
  {
    title: (
      <Tooltip title="Total count of meetings confirmed between the allocator and fund companies">
        <span>Meetings</span>
      </Tooltip>
    ),
    dataIndex: 'meetingCount',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '150px',
    align: 'center',
    className: 'FundColumn',
  },
  {
    title: (
      <Tooltip title="Total count of unsuccessful meeting requests between the allocator and fund companies">
        <span>Unsuccessful Requests</span>
      </Tooltip>
    ),
    dataIndex: 'unsuccessfulMRCount',
    sorter: true,
    cellType: CCTableNumberCell,
    width: '200px',
    align: 'center',
    className: 'FundColumn',
  },
  {
    title: 'Matchmaking Score',
    dataIndex: 'matchmakingScore',
    sorter: true,
    cellType: CCTablePercentageCell,
    render: (value) => (
      <span>{isNil(value) ? '' : `${numeral(value).format('0.[0]%')} `}</span>
    ),
    width: '200px',
    align: 'center',
    className: 'FundColumn',
  },
]

export const getFundAllocatorEngagementColumns = () => [
  {
    title: 'Fund Name',
    dataIndex: 'fundName',
    sorter: true,
    fixed: 'left',
    width: '250px',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    sorter: true,
  },
  {
    title: 'Investor Category',
    dataIndex: 'investorCategory',
    sorter: true,
  },
  {
    title: 'Job Level',
    dataIndex: 'jobLevel',
    sorter: true,
  },
  {
    title: 'Job Function',
    dataIndex: 'jobFunction',
    sorter: true,
  },
  {
    title: 'Action Count',
    dataIndex: 'actionCount',
    sorter: true,
    cellType: CCTableNumberCell,
    align: 'center',
    width: '200px',
  },
  {
    title: 'Distinct Company Count',
    dataIndex: 'distinctCompanyCount',
    sorter: true,
    cellType: CCTableNumberCell,
    align: 'center',
    width: '200px',
  },
]

export const getBackstageUserColumns = (refresh) => [
  {
    title: 'NAME',
    dataIndex: 'firstName',
    sorter: true,
    width: '200px',
    fixed: 'left',
    render: (text, b) => <span>{`${b.firstName} ${b.lastName}`}</span>,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: true,
  },
  {
    title: 'Mobile',
    width: '200px',
    dataIndex: 'mobilePhone',
    sorter: true,
  },
  {
    title: 'Admin?',
    dataIndex: 'isAdmin',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'IR?',
    dataIndex: 'isIR',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'Sales?',
    dataIndex: 'isSales',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'Marketing?',
    dataIndex: 'isMarketing',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'ACTION',
    align: 'center',
    fixed: 'right',
    width: '100px',
    render: (tag) => (
      <ActionButton tag={tag} refresh={refresh} menus={adminUserMenus(tag)} />
    ),
  },
]

export const getAnnouncementColumns = (refresh) => [
  {
    title: 'ANNOUNCEMENT',
    dataIndex: 'title',
    sorter: false,
    render: (text, b) => <span>{b.title}</span>,
  },
  {
    title: 'DISPLAYS TO',
    dataIndex: 'roles',
    render: (text, b) => {
      const parsedRoles = JSON.parse(b.roles)
      let rolesString = ''
      parsedRoles.map((row) => {
        if (row === 1) rolesString = rolesString.concat('Fund Managers, ')
        if (row === 2) rolesString = rolesString.concat('Allocators, ')
        if (row === 3) rolesString = rolesString.concat('Service Providers, ')
        if (row === 6) rolesString = rolesString.concat('CFN, ')
      })
      if (rolesString.trim().length > 0)
        rolesString = rolesString.substring(0, rolesString.length - 2)
      return <span>{rolesString}</span>
    },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    align: 'left',
    sorter: false,
    render: (text, b) => {
      if (b.status == true) {
        return (
          <span
            style={{
              backgroundColor: '#24C477',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',
              width: '55px',
              height: '16px',
              color: 'white',
            }}
          >
            Active
          </span>
        )
      } else {
        return (
          <span
            style={{
              backgroundColor: '#F19F00',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',
              width: '55px',
              height: '16px',
              color: 'white',
            }}
          >
            Disabled
          </span>
        )
      }
    },
  },
  {
    title: 'DATE OF CREATION',
    key: 'createdAt',
    dataValue: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    isFiltered: true,
    align: 'left',
    render: (value) => {
      const format = 'MMMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'ACTIONS',
    width: '100px',
    align: 'center',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={announcementListMenus(tag)}
        refresh={refresh}
      />
    ),
  },
]

export const getDealsColumns = (
  refresh,
  showDealDetailsModal,
  showDealActivityFromGrid,
  showDealDeleteModal
) => [
  {
    title: 'Deal Name',
    dataIndex: 'title',
    align: 'left',
    sorter: false,
    fixed: 'left',
    width: 200,
    ellipsis: true,
    render: (text, b) => (
      <Tooltip title={text}>
        <Button
          style={{
            maxWidth: '168px',
            textDecoration: 'none',
          }}
          onClick={(e) => {
            e.stopPropagation()
            showDealDetailsModal(b.dealId, b.statusName, b.companyCategory)
          }}
        >
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'block',
              textAlign: 'left',
            }}
          >
            {text}
          </span>
        </Button>
      </Tooltip>
    ),
  },
  {
    title: 'STATUS',
    dataIndex: 'statusId',
    align: 'left',
    sorter: true,
    render: (text, b) => {
      if (b.statusName.trim().toLowerCase() == 'draft') {
        return (
          <span
            style={{
              backgroundColor: '#92949C',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',
              width: '55px',
              height: '16px',
              color: 'white',
            }}
          >
            {b.statusName}
          </span>
        )
      }
      if (b.statusName.trim().toLowerCase() == 'live') {
        return (
          <span
            style={{
              backgroundColor: '#24C477',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',
              width: '55px',
              height: '16px',
              color: 'white',
            }}
          >
            {b.statusName}
          </span>
        )
      }
      if (b.statusName.trim().toLowerCase() == 'closed') {
        return (
          <span
            style={{
              backgroundColor: '#D62B34',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',
              width: '55px',
              height: '16px',
              color: 'white',
            }}
          >
            {b.statusName}
          </span>
        )
      }
    },
  },
  {
    title: 'Manager',
    dataIndex: 'manager',
    align: 'left',
    sorter: false,
    render: (text, b) => <span>{b.manager}</span>,
  },
  {
    title: 'Date of Creation',
    key: 'createdAt',
    dataValue: 'createdAt',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    render: (value) => {
      const format = 'MMMM DD, YYYY'
      return (
        <span>
          {value === null || value.createdAt === null
            ? ''
            : moment(value.createdAt).format(format)}
        </span>
      )
    },
  },
  {
    title: 'Start Date',
    key: 'PublishDate',
    dataValue: 'publishDate',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    render: (value) => {
      const format = 'MMMM DD, YYYY'
      return (
        <span>
          {value === null || value.publishDate === null
            ? ''
            : moment(value.publishDate).format(format)}
        </span>
      )
    },
  },
  {
    title: 'End Date',
    key: 'EndDate',
    dataValue: 'endDate',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    render: (value) => {
      const format = 'MMMM DD, YYYY'
      return (
        <span>
          {value === null || value.endDate === null
            ? ''
            : moment(value.endDate).format(format)}
        </span>
      )
    },
  },
  {
    title: 'Divident Return',
    dataIndex: 'DevidedReturn',
    sorter: false,
    align: 'center',
    render: (text, b) => <span>{b.devidedReturn}</span>,
  },
  {
    title: 'Company',
    dataIndex: 'company',
    align: 'left',
    sorter: false,
    render: (text, b) => <span>{b.companyName}</span>,
  },
  {
    title: 'Category',
    dataIndex: 'companyCategory',
    align: 'left',
    sorter: false,
    render: (text, b) => <span>{b.companyCategory}</span>,
  },
  {
    title: 'ACTIONS',
    width: '100px',
    align: 'center',
    fixed: 'right',
    render: (text, b) => {
      if (b.statusName.trim().toLowerCase() == 'draft') {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              showDealDeleteModal(b.dealId, b.title)
            }}
          >
            <span>
              <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#C2C6CC' }} />{' '}
              {'Delete'}
            </span>
          </Button>
        )
      } else {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation()
              showDealActivityFromGrid(b.dealId, b.title)
            }}
          >
            Details
          </Button>
        )
      }
    },
  },
]

export const getFundHealthColumns = (refresh) => [
  {
    title: 'Name',
    dataIndex: 'fundName',
    sorter: true,
    width: '200px',
    fixed: 'left',
  },
  {
    title: 'Tearsheet',
    dataIndex: 'tearsheetStyle',
    sorter: true,
    width: '200px',
    align: 'center',
  },
  {
    title: '# Months Out of Date',
    dataIndex: 'returnMonthsLag',
    width: '150px',
    cellType: CCTableNumberCell,
    align: 'center',
  },
  {
    title: 'Vami not generating',
    dataIndex: 'missingVami',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Stats not updating',
    dataIndex: 'missingStats',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Fund Data Errors',
    dataIndex: 'fundDataErrors',
    width: '250px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Error Description',
    dataIndex: 'fundDataErrorDes',
    width: '150px',
  },
  {
    title: 'No Broad Strategy',
    dataIndex: 'nullBroadStrategy',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Sub Strategy',
    dataIndex: 'nullSubStrategy',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Fund AUM Low',
    dataIndex: 'checkFundAUM',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Firm AUM > Fund AUM',
    dataIndex: 'checkFundvsFirmAUM',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Firm AUM Low',
    dataIndex: 'checkFirmAUM',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Marketing Materials',
    dataIndex: 'missingMM',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Fund Domicile',
    dataIndex: 'missingDomicile',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Manager Country',
    dataIndex: 'missingManagerCountry',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Manager Bio',
    dataIndex: 'missingManagerBio',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Strat Desc',
    dataIndex: 'missingFundStrat',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Proprietary Assets',
    dataIndex: 'missingProprietaryAssets',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Leverage',
    dataIndex: 'missingLeverage',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Largest Position',
    dataIndex: 'missingLeveragePositionSize',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Portfolio Turnover for Liquid',
    dataIndex: 'missingTurnover',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'No Least Liquid Position Unwind',
    dataIndex: 'missingUnwind',
    width: '150px',
    cellType: CCTableReverseCheckCell,
    align: 'center',
  },
  {
    title: 'Total Fund Score',
    dataIndex: 'totalFundScore',
    width: '150px',
    cellType: CCTableNumberCell,
    align: 'center',
  },
]

const dealActivityDateFormat = 'MMMM DD, YYYY'
export const getDealsActivityColumns = (refresh, showActivityDetails) => [
  {
    title: 'Date',
    key: 'dealDate',
    dataValue: 'dealDate',
    dataIndex: 'dealDate',
    cellType: CCTableDateTimeCell,
    sorter: false,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    width: '54%',
    render: (value) => (
      <span>
        {value === null || value.dealDate === null
          ? ''
          : moment(value.dealDate).format(dealActivityDateFormat)}
      </span>
    ),
  },
  {
    title: 'Received Meetings',
    dataIndex: 'meetingRequests',
    align: 'right',
    sorter: false,
    width: '16%',
    render: (text, b) => (
      <Button
        onClick={(e) => {
          showActivityDetails(
            `Received Meetings on ${moment(b.dealDate).format(
              dealActivityDateFormat
            )}`,
            b.dealDate,
            false,
            true
          )
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: 'Deal Views',
    dataIndex: 'dealViews',
    align: 'right',
    sorter: false,
    width: '15%',
    render: (text, b) => (
      <Button
        onClick={(e) => {
          showActivityDetails(
            `Deal Views on ${moment(b.dealDate).format(
              dealActivityDateFormat
            )}`,
            b.dealDate,
            false,
            false
          )
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: 'PDF Views',
    dataIndex: 'dealPdfViews',
    align: 'right',
    sorter: false,
    width: '15%',
    render: (text, b) => (
      <Button
        onClick={(e) => {
          showActivityDetails(
            `PDF Views on ${moment(b.dealDate).format(dealActivityDateFormat)}`,
            b.dealDate,
            true,
            false
          )
        }}
      >
        {text}
      </Button>
    ),
  },
]

export const getDealsActivityDetailsViewColumns = () => [
  {
    title: 'User',
    dataIndex: 'user',
    align: 'left',
    sorter: false,
    width: '50%',
    render: (text, b) => (
      <Button>
        <span>{b.user}</span>
      </Button>
    ),
  },
  {
    title: 'Time',
    key: 'dealDate',
    dataValue: 'dealDate',
    dataIndex: 'dealDate',
    cellType: CCTableDateTimeCell,
    sorter: false,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'time',
    align: 'left',
    width: '50%',
    render: (text, b) => {
      const utcDate = new Date(b.dealDate)
      const offset = utcDate.getTimezoneOffset() / 60
      const hours = utcDate.getHours()
      utcDate.setHours(hours - offset)
      const offsetString = moment.utc(utcDate).local().format('Z')
      const timeString = moment.utc(utcDate).local().format('HH:mm:ss')

      const date = `${timeString} (UTC ${offsetString})`
      return date
    },
  },
]

export const getContentEntryColumns = (refresh) => [
  {
    title: 'Title',
    dataIndex: 'title',
    align: 'left',
    sorter: false,
  },
  {
    title: 'Author',
    key: 'authorContact',
    cellType: CCTableProfilePicsCell,
    contactsMethod: (record) =>
      !isNil(record.authorContact)
        ? [
            {
              contactName: record.authorContact.contactName,
              imageURL: record.authorContact.contactImageUrl,
              firstName: record.authorContact.contactName,
              lastName: record.authorContact.contactName,
            },
          ]
        : [],
    align: 'center',
  },
  {
    title: 'Tagged Contacts',
    key: 'taggedContacts',
    cellType: CCTableProfilePicsCell,
    contactsMethod: (record) =>
      map(record.taggedContacts, (x) => ({
        contactName: x.contactName,
        imageURL: x.contactImageUrl,
        firstName: x.contactName,
        lastName: x.contactName,
      })),
    align: 'center',
  },
  {
    title: 'Categories',
    align: 'center',
    key: 'contentCategories',
    cellType: CCTableTagGroupCell,
    itemsMethod: (record) => map(record.contentCategories, (x) => x.name),
    tagGroupColor: '#2FC5D2',
    filterMethod: getContentEntryCategories,
    filterMappingMethod: (x) => ({ value: x.name, text: x.name }),
  },
  {
    title: 'Publish Date',
    dataIndex: 'publishDateTimeUtc',
    align: 'left',
    cellType: CCTableDateTimeCell,
    sorter: true,
    isFiltered: true,
    render: (record) => (
      <span>
        {isNil(record.publishDateTimeUtc)
          ? ''
          : moment
              .utc(record.publishDateTimeUtc)
              .local()
              .format('MMMM Do YYYY')}
      </span>
    ),
  },
  {
    title: 'End Date',
    dataIndex: 'endDateTimeUtc',
    align: 'left',
    cellType: CCTableDateTimeCell,
    sorter: true,
    isFiltered: true,
    render: (record) => (
      <span>
        {isNil(record.endDateTimeUtc)
          ? ''
          : moment.utc(record.endDateTimeUtc).local().format('MMMM Do YYYY')}
      </span>
    ),
  },
  {
    title: 'Published?',
    dataIndex: 'isPublished',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'Included In Digest?',
    dataIndex: 'includeInWeeklyDigest',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'Featured?',
    dataIndex: 'isFeatured',
    width: '150px',
    sorter: true,
    cellType: CCTableCheckCell,
    align: 'center',
  },
  {
    title: 'ACTIONS',
    width: '100px',
    align: 'center',
    render: (tag) => (
      <ActionButton
        tag={tag}
        menus={contentEntryMenus(tag)}
        refresh={refresh}
      />
    ),
  },
]

export const getDealsActivityDetailsMeetingColumns = () => [
  {
    title: 'Request From',
    dataIndex: 'user',
    align: 'left',
    sorter: false,
    width: '30%',
    render: (text, b) => (
      <Button>
        <span>{b.user}</span>
      </Button>
    ),
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    align: 'left',
    sorter: false,
    width: '30%',
    render: (text, b) => (
      <Button>
        <span>{b.companyName}</span>
      </Button>
    ),
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    align: 'left',
    sorter: true,
    width: '20%',
    render: (text, b) => {
      if (b.status.trim().toLowerCase() == 'pending') {
        return (
          <span
            style={{
              color: '#92949C',
            }}
          >
            {b.status}
          </span>
        )
      }
      if (
        b.status.trim().toLowerCase() == 'confirmed' ||
        b.status.trim().toLowerCase() == 'accepted'
      ) {
        return (
          <span
            style={{
              color: '#24C477',
            }}
          >
            {b.status}
          </span>
        )
      }
      if (
        b.status.trim().toLowerCase() == 'declined' ||
        b.status.trim().toLowerCase() == 'cancelled' ||
        b.status.trim().toLowerCase() == 'expired'
      ) {
        return (
          <span
            style={{
              color: '#D62B34',
            }}
          >
            {b.status}
          </span>
        )
      }
    },
  },
  {
    title: 'Requested At',
    key: 'dealDate',
    dataValue: 'dealDate',
    dataIndex: 'dealDate',
    cellType: CCTableDateTimeCell,
    sorter: false,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'time',
    align: 'left',
    width: '20%',
    render: (text, b) => {
      const utcDate = new Date(b.dealDate)
      const offset = utcDate.getTimezoneOffset() / 60
      const hours = utcDate.getHours()
      utcDate.setHours(hours - offset)
      const offsetString = moment.utc(utcDate).local().format('Z')
      const timeString = moment.utc(utcDate).local().format('HH:mm:ss')

      const date = `${timeString} (UTC ${offsetString})`
      return date
    },
  },
]

export const getMandatesColumns = (
  refresh,
  showActivityDetails,
  showMandateViewDetails
) => [
  {
    title: 'Mandate Name',
    dataIndex: 'name',
    align: 'left',
    sorter: false,
    ellipsis: true,
    width: '8%',
    render: (text, b) => (
      <Tooltip title={text}>
        <Button
          style={{
            maxWidth: '168px',
            textDecoration: 'none',
          }}
          onClick={(e) => {
            e.stopPropagation()
            showMandateViewDetails(b.mandateId, b.name)
          }}
        >
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              display: 'block',
              textAlign: 'left',
            }}
          >
            {text}
          </span>
        </Button>
      </Tooltip>
    ),
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    align: 'left',
    sorter: true,
    width: '7%',
    render: (text, b) => {
      if (b.status.trim().toLowerCase() == 'open') {
        return (
          <span
            style={{
              backgroundColor: '#24C477',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',
              height: '16px',
              color: 'white',
            }}
          >
            {b.status}
          </span>
        )
      }
      if (
        b.status.trim().toLowerCase() == 'closed - filled' ||
        b.status.trim().toLowerCase() == 'closed - unfilled'
      ) {
        return (
          <span
            style={{
              backgroundColor: '#D62B34',
              borderRadius: '24px',
              padding: '1px 8px 1px 8px',

              height: '16px',
              color: 'white',
            }}
          >
            {b.status}
          </span>
        )
      }
    },
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    align: 'left',
    sorter: false,
    width: '8%',
    render: (text, b) => <span>{b.companyName}</span>,
  },
  {
    title: 'Published Date',
    key: 'AddedOn',
    dataValue: 'publishDate',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    width: '7%',
    render: (value) => {
      const format = 'MMMM DD, YYYY'
      return (
        <span>
          {value === null || value.publishDate === null
            ? ''
            : moment(value.publishDate).format(format)}
        </span>
      )
    },
  },
  {
    title: 'Target Date',
    key: 'TargetCompletionDate',
    dataValue: 'targetDate',
    cellType: CCTableDateTimeCell,
    sorter: true,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    width: '6%',
    render: (value) => {
      const format = 'MMMM DD, YYYY'
      return (
        <span>
          {value === null || value.targetDate === null
            ? ''
            : moment(value.targetDate).format(format)}
        </span>
      )
    },
  },
  {
    title: 'Mandate Type',
    dataIndex: 'mandateType',
    sorter: false,
    align: 'left',
    width: '8%',
    render: (text, b) => <span>{b.mandateType}</span>,
  },
  {
    title: '365 Exclusive?',
    dataIndex: 'isExclusive',
    sorter: true,
    align: 'center',
    width: '6%',
    cellType: CCTableCheckCell,
  },
  {
    title: 'ACTIONS',
    width: '8%',
    align: 'center',
    render: (text, b) => (
      <ActionButton
        menus={[
          {
            text: 'View Details',
            onClick: () => showActivityDetails(b.mandateId, b.name),
          },
          {
            text: 'Toggle Exclusive',
            onClick: () =>
              setExclusive(b.mandateId, !b.isExclusive).then(refresh),
          },
          {
            text: 'Delete',
            onClick: () => {
              confirm({
                title: (
                  <span>{`Are you sure you want to delete '${b.name}' ?`}</span>
                ),
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                  deleteMandate(b.mandateId).then(refresh)
                },
              })
            },
          },
        ]}
      />
    ),
  },
]

const mandateActivityDateFormat = 'MMMM DD, YYYY'
export const getMandatesActivityColumns = (refresh, showActivityDetails) => [
  {
    title: 'Date',
    key: 'mandateDate',
    dataValue: 'mandateDate',
    dataIndex: 'mandateDate',
    cellType: CCTableDateTimeCell,
    sorter: false,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'day',
    align: 'left',
    width: '50%',
    render: (value) => (
      <span>
        {value === null || value.mandateDate === null
          ? ''
          : moment(value.mandateDate).format(mandateActivityDateFormat)}
      </span>
    ),
  },
  {
    title: 'Mandate Views',
    dataIndex: 'mandateViews',
    align: 'right',
    sorter: false,
    width: '15%',
    render: (text, b) => (
      <Button
        onClick={(e) => {
          showActivityDetails(
            `Mandate Views on ${moment(b.mandateDate).format(
              mandateActivityDateFormat
            )}`,
            b.mandateDate,
            false,
            false
          )
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: 'PDF Views',
    dataIndex: 'mandatePdfViews',
    align: 'right',
    sorter: false,
    width: '15%',
    render: (text, b) => (
      <Button
        onClick={(e) => {
          showActivityDetails(
            `PDF Views on ${moment(b.mandateDate).format(
              mandateActivityDateFormat
            )}`,
            b.mandateDate,
            true,
            false
          )
        }}
      >
        {text}
      </Button>
    ),
  },
  {
    title: 'Applications',
    dataIndex: 'applicationsNo',
    align: 'right',
    sorter: false,
    width: '15%',
    render: (text, b) => (
      <Button
        onClick={(e) => {
          showActivityDetails(
            `Applications on ${moment(b.mandateDate).format(
              mandateActivityDateFormat
            )}`,
            b.mandateDate,
            true,
            true
          )
        }}
      >
        {text}
      </Button>
    ),
  },
]

export const getMandatesActivityDetailsViewColumns = () => [
  {
    title: 'User',
    dataIndex: 'user',
    align: 'left',
    sorter: false,
    width: '50%',
    render: (text, b) => (
      <Button>
        <span>{b.user}</span>
      </Button>
    ),
  },
  {
    title: 'Time',
    key: 'mandateDate',
    dataValue: 'mandateDate',
    dataIndex: 'mandateDate',
    cellType: CCTableDateTimeCell,
    sorter: false,
    dateTimeFormat: 'MMM YYYY',
    datePickerType: 'time',
    align: 'left',
    width: '50%',
    render: (text, b) => {
      const utcDate = new Date(b.mandateDate)
      const offset = utcDate.getTimezoneOffset() / 60
      const hours = utcDate.getHours()
      utcDate.setHours(hours - offset)
      const offsetString = moment.utc(utcDate).local().format('Z')
      const timeString = moment.utc(utcDate).local().format('HH:mm:ss')

      const date = `${timeString} (UTC ${offsetString})`
      return date
    },
  },
]

function componentToHex(c) {
  const hex = c.toString(16)
  return hex.length == 1 ? `0${hex}` : hex
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}
