import { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  message,
} from 'antd'
import emailValidator from 'email-validator'
import { isEmpty, isNil } from 'lodash'
import OtpInput from 'react-otp-input'
import { connect } from 'react-redux'
import { login } from '../../actions/auth'
import login_logo_bg from '../../assets/login_logo_bg.svg'
import logo_bullets from '../../assets/logo_bullets.svg'
import loginLogo from '../../assets/new-logo.svg'
import sub_logo from '../../assets/sub_logo.svg'
import http from '../../utils/api'
import './Login.less'

const { Option } = Select
const Status = {
  Initial: 1,
  CanRetrieveContact: 2,
  ContactRetrieved: 3,
  CanRequestAccessCode: 4,
  AccessCodeRequested: 5,
  CanSignIn: 6,
}

const initialState = {
  status: Status.Initial,
  buttonToggle: false,
  email: '',
  accessCodeRequestMethod: undefined,
  userId: 0,
  accessCode: '',
  contactDetails: null,
  rememberMe: false,
  error: null,
  confirmation: null,
}

class Login extends Component {
  state = initialState

  get cancelColSpan() {
    const { status } = this.state
    return status >= Status.ContactRetrieved ? 12 : 0
  }

  get nextColSpan() {
    const { status } = this.state
    return status >= Status.ContactRetrieved ? 12 : 24
  }

  get submitIsDisabled() {
    const { status } = this.state
    return status === Status.Initial
  }

  get showAccessCodeRequestMethodField() {
    const { status } = this.state
    return status >= Status.ContactRetrieved
  }

  get showAccessCodeField() {
    const { status } = this.state
    return status >= Status.AccessCodeRequested
  }

  get canSignIn() {
    const { status } = this.state
    return status === Status.CanSignIn
  }

  handleEmailChanged = (e) => {
    const email = e.target.value
    const isEmailValid = emailValidator.validate(email)

    this.setState((state) => ({
      email,
      status: isEmailValid ? Status.CanRetrieveContact : state.status,
    }))
  }

  handleAccessCodeRequestMethodChanged = (e) => {
    const { contactDetails } = this.state
    const contactMethod =
      contactDetails !== null
        ? contactDetails.contactMethods.find((cm) => cm.method === e)
        : null

    this.setState({
      accessCodeRequestMethod: contactMethod.method,
      status: Status.CanRequestAccessCode,
    })
  }

  handleAccessCodeChanged = (accessCode) =>
    this.setState({ accessCode, status: Status.CanSignIn })

  handleCancel = () => this.setState(initialState)

  retrieveContact = (email) => {
    this.setState({
      buttonToggle: true,
    })

    http
      .post('/auth/user', { email })
      .then((response) => {
        const user = response.data
        this.setState({
          userId: user.userId,
          buttonToggle: false,
          contactDetails: user,
          status:
            user.contactMethods.length === 1
              ? Status.CanRequestAccessCode
              : Status.ContactRetrieved,
          accessCodeRequestMethod:
            user.contactMethods.length === 1
              ? user.contactMethods[0].method
              : undefined,
        })
        console.log(user.userId)
      })
      .catch((err) => {
        let message = `There is no contact with the address ${email}.`
        if (err.response) {
          message = err.response.data
        }
        console.log(message)

        this.setState({
          buttonToggle: false,
          error: message,
        })
      })
  }

  requestAccessCode = (userId, method) => {
    http
      .post('/auth/access-code', { userId, method })
      .then((response) => {
        const stateChanges = {
          status: Status.AccessCodeRequested,
        }
        this.setState({
          confirmation: response.data.message,
        })
        if (response.data.message) message.success(response.data.message)
        else message.success(response.data)
        const { accessCode } = response.data
        if (isNil(accessCode) || isEmpty(accessCode)) {
          this.setState(stateChanges)
        } else {
          this.setState({
            ...stateChanges,
            accessCode,
            status: Status.CanSignIn,
            confirmation: response.data,
          })
        }
      })
      .catch(() => {
        this.setState({
          buttonToggle: false,
          error: 'Access Code request failed.',
        })
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { status, email, userId, accessCodeRequestMethod, accessCode } =
      this.state

    const { login, history } = this.props

    switch (status) {
      case Status.CanRetrieveContact:
        this.retrieveContact(email)
        break
      case Status.CanRequestAccessCode:
        this.requestAccessCode(userId, accessCodeRequestMethod)
        break
      case Status.CanSignIn:
        login(userId, accessCode)
          .then(() => history.push('/'))
          .catch((err) =>
            this.setState({
              error: err.response ? err.response.message : err.message,
            })
          )
        break
      default:
        break
    }
  }

  render() {
    const {
      email,
      accessCodeRequestMethod,
      contactDetails,
      accessCode,
      rememberMe,
      error,
    } = this.state

    const { width } = this.props
    return (
      <div style={{ display: 'flex' }}>
        {width > 576 && (
          <div
            style={{
              backgroundColor: '#151517',
              //backgroundImage: `url(${login_logo_bg})`,
              width: '30%',
              height: 'auto',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'bottom-center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ marginTop: 'auto' }}>
              <div className="cc-heading-custom cc-text-color-summit">
                Welcome
              </div>
              <ul className="pl-4">
                <li className="type-body-regular-md text-white my-2">
                  <div>Make new connections</div>
                </li>
                <li className="type-body-regular-md text-white my-2">
                  <div>Discover insights</div>
                </li>
                <li className="type-body-regular-md text-white my-2">
                  <div>Close deals anywhere</div>
                </li>
              </ul>
            </div>
            <div
              className="cc-body-text"
              style={{
                color: 'white',
                width: '50%',
                textAlign: 'left',
                marginTop: 'auto',
                marginBottom: '32px',
              }}
            >
              Apex Invest is the online network for professionals in the
              alternative investment industry.
            </div>
          </div>
        )}
        <div className="cc-login-container">
          <div className="cc-login-content" style={{ marginLeft: '10%' }}>
            <div
              className="cc-login-logo"
              style={{ backgroundImage: `url(${loginLogo})` }}
            />
            <div
              className="cc-login-sub-logo"
              style={{
                backgroundImage: `url(${sub_logo})`,
                backgroundPosition: 'left',
              }}
            />
            <h2 className="cc-login-form-title">Sign In</h2>
            {!isNil(error) && !isEmpty(error) && (
              <Alert type="error" message={error} />
            )}
            <Form
              className="cc-login-form"
              autoComplete="off"
              onSubmit={this.handleSubmit}
            >
              <Form.Item>
                <Input
                  placeholder="Email"
                  type="email"
                  size="large"
                  value={email}
                  onChange={this.handleEmailChanged}
                />
              </Form.Item>

              {this.showAccessCodeRequestMethodField && (
                <Form.Item>
                  <Select
                    placeholder="How do you want to receive your Access Code"
                    size="large"
                    value={accessCodeRequestMethod}
                    onChange={this.handleAccessCodeRequestMethodChanged}
                  >
                    {console.log('contactdetails', contactDetails)}
                    {contactDetails !== null &&
                      contactDetails.contactMethods.map((cm) => (
                        <Option key={cm.method} value={cm.method}>
                          {cm.method}:<b>{cm.contact}</b>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}

              {this.showAccessCodeField && (
                <Form.Item>
                  <OtpInput
                    containerStyle="cc-otp-input-container"
                    inputStyle={{ width: '42px' }}
                    numInputs={6}
                    onChange={this.handleAccessCodeChanged}
                    value={accessCode}
                  />
                </Form.Item>
              )}

              <Form.Item>
                <Row>
                  <Col span={this.cancelColSpan}>
                    <Button
                      type="default"
                      htmlType="reset"
                      size="large"
                      block
                      onClick={this.handleCancel}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={this.nextColSpan}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="large"
                      block
                      disabled={
                        this.submitIsDisabled || this.state.buttonToggle
                      }
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Checkbox
                  value={rememberMe}
                  onChange={this.handleRememberMeChanged}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
}

const mapDispatchToProps = { login }

export default connect(null, mapDispatchToProps)(Login)
