import PropTypes from 'prop-types'
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'antd'
import { isEmpty, isNil } from 'lodash'
import './ServiceProviderContent.less'
import './ServiceProviderContact.less'

const getClickableLink = (link) =>
  link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `https://${link}`

const ServiceProviderContent = ({
  serviceProviders,
  canSendMeetingRequest,
  onSendRequest = () => {},
  onSendMessage = () => {},
  hideActions,
}) => {
  return (
    <Row style={{ height: '400px' }}>
      <Col span={18}>
        <div className="ServiceProviderContact-description">
          Don’t hesitate to connect with us on Apex Invest
          {!hideActions && (
            <span>
              {' '}
              by
              <a onClick={() => onSendMessage()}> sending a message </a>
              {canSendMeetingRequest && (
                <span>
                  or <a onClick={() => onSendRequest()}>a meeting request</a>
                </span>
              )}
            </span>
          )}
          .
        </div>
      </Col>
      <Col span={6} style={{ display: 'flex' }}>
        {!isNil(serviceProviders.facebookUrl) &&
          !isEmpty(serviceProviders.facebookUrl) && (
            <a
              href={getClickableLink(serviceProviders.facebookUrl)}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="ServiceProviderContact-icon"
                color="#2FC5D2"
                size="3x"
                icon={faFacebookSquare}
              />
            </a>
          )}
        {!isNil(serviceProviders.twitterUrl) &&
          !isEmpty(serviceProviders.twitterUrl) && (
            <a
              href={getClickableLink(serviceProviders.twitterUrl)}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="ServiceProviderContact-icon"
                color="#2FC5D2"
                size="3x"
                icon={faTwitter}
              />
            </a>
          )}
        {!isNil(serviceProviders.linkedInUrl) &&
          !isEmpty(serviceProviders.linkedInUrl) && (
            <a
              href={getClickableLink(serviceProviders.linkedInUrl)}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="ServiceProviderContact-icon"
                color="#2FC5D2"
                size="3x"
                icon={faLinkedin}
              />
            </a>
          )}
        {!isNil(serviceProviders.instagramUrl) &&
          !isEmpty(serviceProviders.instagramUrl) && (
            <a
              href={getClickableLink(serviceProviders.instagramUrl)}
              rel="noreferrer"
              target="_blank"
            >
              <FontAwesomeIcon
                className="ServiceProviderContact-icon"
                color="#2FC5D2"
                size="3x"
                icon={faInstagram}
              />
            </a>
          )}
      </Col>
    </Row>
  )
}

ServiceProviderContent.propTypes = {
  serviceProviders: PropTypes.array.isRequired,
  canSendMeetingRequest: PropTypes.bool,
  onSendMessage: PropTypes.bool,
  onSendRequest: PropTypes.func,
  hideActions: PropTypes.bool,
}

export default ServiceProviderContent
