import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Modal, Row } from 'antd'
import { Formik } from 'formik'
import { get, map } from 'lodash'
import moment from 'moment'
import FloatingInput from '../../components/FloatingInput'
import FloatingSelect from '../../components/FloatingSelect'
import { BASE_URL } from '../../config'
import http from '../../utils/api'
import { getInitialValues } from '../DynamicForm/DynamicForm'
import FormField from '../Forms/Fields/FormField'
import UploadFileField from '../Forms/Fields/UploadFileField'
import { getTierOptions } from '../../actions/company'
import FloatingDatePicker from '../FloatingDatePicker'
import { TIER_PRO } from '../../constants/types/tiers'

const GeneralInfoModal = ({
  visible,
  handleOk,
  handleCancel,
  company,
  companyId,
  changeCompanyName,
  questions,
  answers,
  questionsWithOptions,
  category,
}) => {
  const [data, setData] = useState(company)
  const [tiers, setTiers] = useState([])
  const [proExpiringAtVisible, setProExpiringAtVisible]= useState(data.tierId === TIER_PRO);

  const [selectedTier, setSelectedTier] = useState(company.tierId)
  const [selectedProExpiringAt, setSelectedProExpiringAt] = useState(company.proExpiringAt)
  
  useEffect(() => {
    getTierOptions().then((res) => setTiers(res.data))
  }, [])

  const updateData = (field, value) => {
    var newState = { ...data }
    newState[field] = value
    setData(newState)
  }

  const initialValues = getInitialValues(
    questions,
    answers,
    questionsWithOptions
  )

  const saveChanges = (values) => {
    if (category === 'serviceprovider') {
      const answersData = map(questions, (q) => ({
        serviceProviderQuestionId: q.serviceProviderQuestionId,
        shortName: q.shortName,
        serviceProviderQuestionAnswerIds: questionsWithOptions.has(
          q.questionTypeId
        )
          ? get(values, q.shortName, [])
          : [],
        responseText: !questionsWithOptions.has(q.questionTypeId)
          ? values[q.shortName]
          : null,
      }))

      var imageURL = answersData.find((a) => a.shortName === 'Logo')
      if (imageURL != undefined) data.imageURL = imageURL.responseText

      http
        .put(`/companies/edit/${companyId}`, data)
        .then((response) => {
          handleOk()
          changeCompanyName(data.name)
        })
        .catch((error) => {
          console.log(error)
        })

      http
        .patch(`/serviceproviders/0/${companyId}`, {
          answers: answersData,
        })
        .then((response) => {
          handleOk()
        })
    } else {
      http
        .put(`/companies/edit/${companyId}`, data)
        .then((response) => {
          handleOk()
          changeCompanyName(data.name)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const onValueChanged = (newValue, id) => {
    if (id === 'tier') {
        const tier = tiers.find((x) => x.id === Number(newValue))
        data.tierId = tier.id
        data.tierName = tier.name
        if (tier.id === TIER_PRO)
        {
          setProExpiringAtVisible(true);
        }
        else{
          setProExpiringAtVisible(false);
          data.proExpiringAt=null;
        }
        setSelectedTier(newValue)
    }
    if (id === 'proExpiringAt') {
      data.proExpiringAt=newValue;
      setSelectedProExpiringAt(newValue);
    }
  }

  const onClose = () => {
    setData(company)
    handleCancel()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        saveChanges(values)
      }}
    >
      {({
        values,
        errors,
        isValid,
        dirty,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setErrors,
      }) => (
        <Modal
          className="cc-modal-accept"
          title={`Edit General Infomation`}
          visible={visible}
          okText={`Save Changes`}
          cancelText="Close"
          onOk={handleSubmit}
          onCancel={onClose}
          okButtonProps={{
            disabled: false,
            style: { backgroundColor: '#2FC5D2', borderStyle: 'none' },
          }}
          centered={true}
          cancelButtonProps={{ disabled: false }}
        >
          <div style={{ padding: '13px 16px 32px 32px' }}>
            <div>
              <Row>
                <Col span={24}>
                  <FloatingInput
                    value={data.name}
                    name="Name"
                    nameField="Name"
                    onChange={(e) => {
                      updateData('name', e)
                    }}
                  />
                </Col>
                <Col span={24}>
                  <FloatingSelect
                    name="Tier"
                    handleChange={false}
                    value={selectedTier}
                    onChange={onValueChanged}
                    id={'tier'}
                    options={tiers.map((x) => ({ value: x.id, text: x.name }))}
                  />
                </Col>
                <Col span={24}>
                  <FloatingDatePicker
                    showTime
                    name="Pro Expiring At"
                    id={'proExpiringAt'}
                    nameField="proExpiringAt"
                    className="cc-background-input"
                    size="large"
                    width="100%"
                    visible={proExpiringAtVisible}
                    defaultValue={data.proExpiringAt}
                    onChange={(e) => {
                      updateData('proExpiringAt', e)
                    }}
                  />
                </Col>
                <Col span={24}>
                  <FloatingInput
                    value={moment(data.createdAt).format('MMMM D, YYYY LT')}
                    disabled={true}
                    name="Date Of Creation"
                    nameField="createdAt"
                    onChange={(e) => {}}
                  />
                </Col>
                <Col span={24}>
                  <FloatingInput
                    value={moment(data.updatedAt).format('MMMM D, YYYY LT')}
                    disabled={true}
                    name="Last Updated"
                    nameField="updatedAt"
                    onChange={(e) => {}}
                  />
                </Col>
                <Col span={24}>
                  <FloatingInput
                    value={data.companyWebsite}
                    name="Website"
                    nameField="Website"
                    onChange={(e) => {
                      updateData('companyWebsite', e)
                    }}
                  />
                </Col>
                <Col span={24}>
                  <FloatingInput
                    value={data.phoneNumber}
                    name="Phone Number"
                    nameField="Phone"
                    onChange={(e) => {
                      updateData('phoneNumber', e)
                    }}
                  />
                </Col>
                {category && category !== 'serviceprovider' && (
                  <Col span={24}>
                    <h3>Logo</h3>
                    <UploadFileField
                      accept=".jpg, .png, .bmp"
                      listType="picture"
                      hint="File Types: .PNG, .JPG Max File Size: 4MB"
                      action={`${BASE_URL}uploads`}
                      value={data.imageURL}
                      name="Logo"
                      onChange={(e) => {
                        updateData('imageURL', e)
                      }}
                    />
                  </Col>
                )}
              </Row>
            </div>
            <div style={{ paddingBottom: '55px' }}>
              {questions
                .filter((q) => q.stepNumber == 2)
                .map((question) => (
                  <div>
                    <p>{question.shortName}:</p>
                    <FormField
                      key={question.serviceProviderQuestionId}
                      question={question}
                      values={values}
                      errors={errors}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      )}
    </Formik>
  )
}

GeneralInfoModal.GeneralInfoModal = {
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  companyId: PropTypes.number.isRequired,
}

export default GeneralInfoModal
