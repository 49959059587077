import { Component } from 'react'
import { DatePicker } from 'antd'
import './FloatingDatePicker.less'
import moment from 'moment'

class FloatingDatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue!==null?
        moment.utc(props.defaultValue,
                    moment.ISO_8601
                    ) : props.defaultValue
    }
  }

  valueChanged = (e) => {
   let momentValue=null;
    if(e!==null)
    {
    momentValue= moment.utc(
      e,
      moment.ISO_8601
    );
  }
    this.setState({
      value: momentValue,
    })

    if (typeof this.props.onChange === 'function')
      this.props.onChange( momentValue, this.props.id)
  }

  render() {
    const value =
      typeof this.props.value === 'undefined' || this.props.value === null
        ? this.state.value
        : this.props.value
    const anyText = value !== null
    let classes = !anyText
      ? 'cc-floating-datepicker-notext'
      : 'cc-floating-datepicker-text'
    classes += ' cc-floating-datepicker'
    let placeholderClasses = !anyText
      ? 'placeholder-movement-notext'
      : 'placeholder-movement-text'
    placeholderClasses += ' cc-floating-placeholder'

    if (this.props.visible===false)
    {
      return(<></>)
    }
    return (
      <div className="floating-datepicker-row">
        <DatePicker
          size="large"
          value={value}
          disabled={this.props.disabled}
          placeholder={this.props.name}
          name={this.props.id ? this.props.id : this.props.nameField}
          onChange={this.valueChanged}
          className={classes}
          id={this.props.id}
          defaultValue={moment.utc(value,moment.ISO_8601)}
        />
        <span className={placeholderClasses}>{this.props.name}</span>
      </div>
    )
  }
}

export default FloatingDatePicker
