import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Spin, Table } from 'antd'
import { isNil } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useSelector } from 'react-redux'
import loaderImage from '../../assets/loading-image.svg'
import http from '../../utils/api'
import { transformColumns, transformPagination } from '../../utils/contextTable'
import TableHeader from '../TableHeader'
import './ContextTable.less'

export const CCTableLinkCell = 'CCTABLE_LINK_CELL'
export const CCTableContactLinkCell = 'CCTABLE_CONTACT_LINK_CELL'
export const CCTableTagGroupCell = 'CCTABLE_TAG_GROUP_CELL'
export const CCTableActionCell = 'CCTABLE_ACTION_CELL'
export const CCTableProfilePicsCell = 'CCTABLE_PROFILE_PICS_CELL'
export const CCTableDateTimeCell = 'CCTABLE_DATETIME_CELL'
export const CCTableNumberCell = 'CCTABLE_NUMBER_CELL'
export const CCTableCurrencyCell = 'CCTABLE_CURRENCY_CELL'
export const CCTableYesNoCell = 'CCTABLE_YESNO_CELL'
export const CCTablePercentageCell = 'CCTABLE_PERCENTAGE_CELL'
export const CCTableDollarChangeCell = 'CCTABLE_DOLLAR_CHANGE_CELL'
export const CCTableLambdaCell = 'CCTABLE_LAMBDA_CELL'
export const CCTableCheckCell = 'CCTABLE_CHECK_CELL'
export const CCTableReverseCheckCell = 'CCTABLE_REVERSE_CHECK_CELL'

const ContextTable = ({
  scroll,
  pagination,
  sorter,
  defaultSearchTerm,
  tableTitle,
  columns,
  dataSource,
  dataMethod,
  fetchMethod,
  onTableChanged,
  eventId = null,
  //companyEventId = null,
  eventParticipantId = null,
  fundEventId = null,
  eventCompanyId = null,
  companyContactId = null,
  fundId = null,
  companyId = null,
  refresh,
  refreshData = false,
  rowSelected,
  clickEvent,
  savedQueryMethod,
  hideSearch = false,
}) => {
  const [data, setData] = useState(null)
  const [tablePagination, setTablePagination] = useState({})
  const [tableParameters, setTableParameters] = useState({
    pagination: {},
    filters: {},
    sorter: sorter || {},
    searchTerm: {},
  })
  const [loadData, setLoadData] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [internalRefresh, setInternalRefresh] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [initial, setInitial] = useState(true)
  let currentFilter = null
  let currentSorter = null
  useEffect(() => {
    if (savedQueryMethod && initial) {
      console.log('INITIAL')
      savedQueryMethod().then((res) => {
        if (!isNil(defaultSearchTerm) && defaultSearchTerm != '') {
          if (isNil(res.data.searchTerm.searchValue)) {
            res.data.searchTerm.searchValue = defaultSearchTerm
          }
        }
        setTableParameters(res.data)
        setInitial(false)
      })
    }
  }, [])

  if (isNil(dataSource) && loadData && (!initial || !savedQueryMethod)) {
    console.log('fetching data...')
    console.log(tableParameters)
    console.log(
      eventId,
      eventParticipantId,
      fundEventId,
      eventCompanyId,
      companyId,
      companyContactId,
      fundId
    )
    dataMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm,
      eventId,
      //companyEventId,
      eventParticipantId,
      fundEventId,
      eventCompanyId,
      companyContactId,
      fundId,
      companyId
    ).then((response) => {
      console.log('data fetched')
      if (!isNil(onTableChanged)) onTableChanged(response.data.result)
      setLoadData(false)
      setData(response.data.result)
      setTablePagination(response.data)
    })
  }

  if (!isNil(dataSource) && !isNil(fetchMethod) && loadData) {
    console.log('FETCH')
    fetchMethod(
      tableParameters.pagination,
      tableParameters.filters,
      tableParameters.sorter,
      tableParameters.searchTerm
    )
    setLoadData(false)
    setSpinnerLoading(true)
  }

  useEffect(() => {}, [refresh])

  useEffect(() => {
    console.log('internal refresh')
  }, [internalRefresh])

  useEffect(() => {
    console.log('change in useeffect?')
    setLoadData(true)
  }, [refreshData])

  useEffect(() => {
    console.log('data changed, in useeffect now')
    console.log(data)
    if (!isNil(data) || !isNil(dataSource)) {
      getColumnFilters()
      setSpinnerLoading(false)
    }
  }, [data, dataSource])

  const getColumnFilters = () => {
    console.log('getting column info')
    const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
    if (!isNil(filteredColumns)) {
      filteredColumns.forEach((column) => {
        if (isNil(filterData[column.key])) {
          column.filterMethod().then((response) => {
            const filter = filterData
            if (!isNil(column.filterMappingMethod))
              filter[column.key] = response.data.map((x) =>
                column.filterMappingMethod(x)
              )
            else filter[column.key] = response.data
            setFilterData(filter)
            setInternalRefresh(moment())
          })
        }
      })
    }
  }

  const filteredColumns = columns.filter((x) => !isNil(x.filterMethod))
  if (!isNil(filteredColumns)) {
    filteredColumns.forEach((column) => {
      if (!isNil(filterData[column.key])) {
        column.filters = filterData[column.key]
      }
    })
  }

  columns = transformColumns(columns, rowSelected)
  pagination = transformPagination(tablePagination)

  const tableChanged = (pagination, filters, sorter) => {
    currentFilter = filters
    currentSorter = sorter
    console.log('table changed', pagination, filters, sorter)
    if (!isEmpty(sorter) && !isEmpty(filters)) {
      console.log('tablefilters', tableParameters.filters)
      console.log('tablesorter', tableParameters.sorter)
      if (
        isEmpty(tableParameters.filters) ||
        tableParameters.filters.columns.length == 0
      ) {
        console.log('filter empty')
        currentFilter = filters
      }
      if (isEmpty(tableParameters.sorter)) {
        console.log('sorter empty')
        currentSorter = sorter
      }
    }

    let filterArray = {
      columns: [],
    }
    console.log('columns', columns)
    for (const propertyName in currentFilter) {
      const column = columns.find(
        (x) => x.dataIndex === propertyName || x.key === propertyName
      )
      const { columnType } = column

      const newCol = {
        column: propertyName,
        values: currentFilter[propertyName],
        columnType,
      }

      filterArray.columns.push(newCol)
    }

    console.log('FILTERS', filterArray)

    pagination.total = null

    setTableParameters({
      pagination,
      filters: filterArray,
      sorter: currentSorter,
      searchTerm: tableParameters.searchTerm,
    })
    setLoadData(true)
  }

  const searchChanged = (searchTerm) => {
    setTableParameters({
      pagination: { pageSize: tableParameters.pagination.pageSize },
      filters: tableParameters.filters,
      sorter: tableParameters.sorter,
      searchTerm: {
        searchValue: searchTerm,
      },
    })
    setLoadData(true)
  }

  return (
    <>
      {!hideSearch && (
        <TableHeader headerTitle={tableTitle} onSearch={searchChanged} />
      )}
      <Spin
        size="small"
        spinning={
          (isNil(data) && isNil(dataSource)) ||
          loadData ||
          spinnerLoading === true
        }
        indicator={
          <img
            style={{ marginLeft: '-50px', width: '100px', height: '100px' }}
            src={loaderImage}
          />
        }
      >
        <Table
          scroll={scroll}
          pagination={pagination}
          rowKey="id"
          columns={columns}
          dataSource={isNil(data) ? dataSource : data}
          onChange={tableChanged}
          onRow={(record, rowIndex) => {
            if (clickEvent) {
              return {
                onClick: () => {
                  clickEvent(record)
                },
              }
            }
          }}
        />
      </Spin>
    </>
  )
}
ContextTable.propTypes = {
  scroll: PropTypes.object,
  pagination: PropTypes.object,
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataSource: PropTypes.array,
  fetchMethod: PropTypes.func,
  dataMethod: PropTypes.func,
  onTableChanged: PropTypes.func,
  refresh: PropTypes.bool,
  refreshData: PropTypes.bool,
  rowSelected: PropTypes.func,
  clickEvent: PropTypes.func,
  hideSearch: PropTypes.bool,
}

export default ContextTable
