import PropTypes from 'prop-types'
import { Button, IconButton } from '@context365/button'
import {
  DatePicker,
  Input,
  MultiSelect,
  Option,
  Select,
  TextArea,
} from '@context365/forms'
import { DeleteOutline, Search } from '@context365/icons'
import { Divider } from 'antd'
import produce from 'immer'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import { BASE_URL } from '../../../config'
import { formatNumber, fromMillions, toMillions } from '../../../utils/helpers'
import UploadFileField from '../../Forms/Fields/UploadFileField'

const generateEmptyAssetAmount = () => ({
  valuationDate: null,
  amount: null,
})

const generateEmptyAsset = (assetCategoryId) => ({
  assetCategoryId,
  amounts: [generateEmptyAssetAmount()],
})

const AssetField = ({ assetCategory, assets, onChange, onAdd, onRemove }) => (
  <div className="space-y-4">
    <div className="type-subtitle-xs">{assetCategory.name}</div>
    {map(assets, (a, i) => (
      <div key={i} className="grid grid-cols-2 gap-6">
        <Input
          required
          label={`${assetCategory.name} ${
            assetCategory.isCurrency
              ? '(millions)'
              : assetCategory.isPercentage
              ? '(percent)'
              : '(total count)'
          }`}
          type="number"
          value={
            assetCategory.isCurrency
              ? toMillions(a.amount ?? '')
              : a.amount ?? ''
          }
          helpMessage={formatNumber(a.amount)}
          prefix={assetCategory.isCurrency && 'US$'}
          onChange={(e) =>
            onChange(
              i,
              'amount',
              assetCategory.isCurrency
                ? fromMillions(e.target.value)
                : e.target.value
            )
          }
        />
        <div className="flex items-start gap-4">
          <DatePicker
            required
            label={`${assetCategory.name} valuation date`}
            value={a.valuationDate ? new Date(a.valuationDate) : null}
            mode="month"
            onChange={(date) => onChange(i, 'valuationDate', date)}
          />
          <IconButton
            className="mt-5"
            variant="link"
            label="delete"
            icon={<DeleteOutline className="text-red-110" />}
            onClick={() => onRemove(i)}
          />
        </div>
      </div>
    ))}
    <div>
      <Button variant="link" onClick={() => onAdd()}>
        + Add Snapshot
      </Button>
    </div>
  </div>
)

const Overview = ({ company, options, updateCompany }) => {
  const handleAddAsset = (assetCategoryId) => {
    const categoryIndex = findIndex(
      company.assets,
      (a) => a.assetCategoryId === assetCategoryId
    )
    const newAssets = produce(company.assets, (newState) => {
      categoryIndex < 0
        ? newState.push(generateEmptyAsset(assetCategoryId))
        : newState[categoryIndex].amounts.push(generateEmptyAssetAmount())
    })
    updateCompany('assets', newAssets)
  }

  const handleRemoveAsset = (assetCategoryId, amountIndex) => {
    const categoryIndex = findIndex(
      company.assets,
      (a) => a.assetCategoryId === assetCategoryId
    )
    const newAssets = produce(company.assets, (newState) => {
      newState[categoryIndex].amounts.splice(amountIndex, 1)
    })
    updateCompany('assets', newAssets)
  }

  const handleChangeAsset = (assetCategoryId, amountIndex, key, value) => {
    const categoryIndex = findIndex(
      company.assets,
      (a) => a.assetCategoryId === assetCategoryId
    )
    const newAssets = produce(company.assets, (newState) => {
      newState[categoryIndex].amounts[amountIndex][key] = value
    })
    updateCompany('assets', newAssets)
  }

  return (
    <div className="grid grid-cols-2 gap-6">
      <Input label="Company name" 
        value={company.name}
        onChange={(e) => updateCompany('name', e.target.value)}
       />
      <Input
        label="Salesforce ID"
        value={company.salesforceAccountId}
        disabled
      />
      <div className="type-body-regular-sm text-grey-900 mb-4">
        <div className="mb-1">Company logo</div>
        <UploadFileField
          accept=".jpg, .png, .bmp"
          listType="picture"
          hint="File Types: .PNG, .JPG Max File Size: 4MB"
          action={`${BASE_URL}uploads`}
          value={company.avatarImageUrl}
          name="Logo"
          onChange={(url) => {
            updateCompany('avatarImageUrl', url)
          }}
        />
        <div className="type-body-regular-xs">
          Click or drag and drop an image file to upload it.
        </div>
      </div>
      <Select
        className="col-start-1"
        label="Primary investor category"
        value={company.primaryInvestorCategoryId}
        onChange={(value) => updateCompany('primaryInvestorCategoryId', value)}
      >
        {map(options?.investorCategories, (category) => (
          <Option value={category.id}>{category.name}</Option>
        ))}
      </Select>
      <MultiSelect
        label="Secondary investor category"
        value={company.secondaryInvestorCategoryIds}
        onChange={(value) =>
          updateCompany('secondaryInvestorCategoryIds', value)
        }
      >
        {map(options?.investorCategories, (category) => (
          <MultiSelect.Option value={category.id}>
            {category.name}
          </MultiSelect.Option>
        ))}
      </MultiSelect>
      <Input
        label="Portfolio size"
        type="number"
        value={company.portfolioSize}
        helpMessage={formatNumber(company.portfolioSize)}
        prefix="US$"
        onChange={(e) => updateCompany('portfolioSize', e.target.value)}
      />
      <TextArea
        label="Company description"
        value={company.description}
        className="w-full col-span-2"
        onChange={(e) => updateCompany('description', e.target.value)}
      />
      <Divider className="col-span-2" style={{ margin: 0 }} />
      <div className="type-subtitle-sm col-span-2">Assets</div>
      <div className="col-span-2">
        {map(options.assetCategories, (ac, i) => (
          <div key={i}>
            <AssetField
              assetCategory={ac}
              assets={
                find(
                  company.assets,
                  (a) => a.assetCategoryId === ac.assetCategoryId
                )?.amounts
              }
              onAdd={() => handleAddAsset(ac.assetCategoryId)}
              onRemove={(amountIndex) =>
                handleRemoveAsset(ac.assetCategoryId, amountIndex)
              }
              onChange={(amountIndex, key, value) =>
                handleChangeAsset(ac.assetCategoryId, amountIndex, key, value)
              }
            />
            <Divider />
          </div>
        ))}
      </div>
      <div className="type-subtitle-sm col-span-2">Address Information</div>
      <Input
        label="Zip/Postal code"
        value={company.postalCode}
        suffix={<Search />}
        disabled
      />
      <div />
      <Input label="Continent" value={company.continent} disabled />
      <div />
      <Input label="Country" value={company.country} disabled />
      <div />
      <Input label="State/Province" value={company.stateProvince} disabled />
      <div />
      <Input
        label="Address line 1"
        value={company.address1}
        className="col-span-2"
        disabled
      />
      <Input
        label="Address line 2"
        value={company.address2}
        className="col-span-2"
        disabled
      />
      <Input
        label="Address line 3"
        value={company.address3}
        className="col-span-2"
        disabled
      />
      <Divider className="col-span-2" style={{ margin: 0 }} />
      <div className="type-subtitle-sm col-span-2">Contact</div>
      <Input
        label="Phone number"
        value={company.generalPhoneNumber}
        helpMessage="Include area code"
        onChange={(e) => updateCompany('generalPhoneNumber', e.target.value)}
      />
      <Input
        label="General company email address"
        value={company.generalEmail}
        helpMessage="contact@fundmanager.com"
        onChange={(e) => updateCompany('generalEmail', e.target.value)}
      />
      <Divider className="col-span-2" style={{ margin: 0 }} />
      <div className="type-subtitle-sm col-span-2">Contact</div>
      <Input
        label="Company website"
        value={company.website}
        onChange={(e) => updateCompany('website', e.target.value)}
      />
      <Input
        label="LinkedIn"
        value={company.linkedIn}
        onChange={(e) => updateCompany('linkedIn', e.target.value)}
      />
      <Input
        label="Twitter"
        value={company.twitter}
        onChange={(e) => updateCompany('twitter', e.target.value)}
      />
      <Input
        label="YouTube"
        value={company.youtube}
        onChange={(e) => updateCompany('youtube', e.target.value)}
      />
      {/*<Input label="Additional link" value={company.name} suffix={<Link />} />*/}
    </div>
  )
}

Overview.propTypes = {
  company: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  updateCompany: PropTypes.func.isRequired,
}

export default Overview
