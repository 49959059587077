import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
import { isEmpty, isNil } from 'lodash'
import './ServiceProviderContent.less'

const getClickableLink = (link) =>
  link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `https://${link}`

const ServiceProviderContent = ({ serviceProviders }) => {
  return (
    <div className="ServiceProviderContent">
      {!isNil(serviceProviders.blogUrl) &&
        !isEmpty(serviceProviders.blogUrl) && (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="paddedRow"
          >
            <Col span={12}>Blog:</Col>
            <Col span={12}>
              <a
                href={getClickableLink(serviceProviders.blogUrl)}
                rel="noreferrer"
                target="_blank"
              >
                {serviceProviders.blogUrl}
              </a>
            </Col>
          </Row>
        )}
      {!isNil(serviceProviders.caseStudiesUrl) &&
        !isEmpty(serviceProviders.caseStudiesUrl) && (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="paddedRow"
          >
            <Col span={12}>Case Studies:</Col>
            <Col span={12}>
              <a
                href={getClickableLink(serviceProviders.caseStudiesUrl)}
                rel="noreferrer"
                target="_blank"
              >
                {serviceProviders.caseStudiesUrl}
              </a>
            </Col>
          </Row>
        )}
      {!isNil(serviceProviders.webCastsUrl) &&
        !isEmpty(serviceProviders.webCastsUrl) && (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="paddedRow"
          >
            <Col span={12}>Webcasts:</Col>
            <Col span={12}>
              <a
                href={getClickableLink(serviceProviders.webCastsUrl)}
                rel="noreferrer"
                target="_blank"
              >
                {serviceProviders.webCastsUrl}
              </a>
            </Col>
          </Row>
        )}
      {!isNil(serviceProviders.whitepapersAndGuidesUrl) &&
        !isEmpty(serviceProviders.whitepapersAndGuidesUrl) && (
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="paddedRow"
          >
            <Col span={12}>Whitepapers & Guides:</Col>
            <Col span={12}>
              <a
                href={getClickableLink(
                  serviceProviders.whitepapersAndGuidesUrl
                )}
                rel="noreferrer"
                target="_blank"
              >
                {serviceProviders.whitepapersAndGuidesUrl}
              </a>
            </Col>
          </Row>
        )}
    </div>
  )
}

ServiceProviderContent.propTypes = {
  serviceProviders: PropTypes.array.isRequired,

  handleInfiniteOnLoad: PropTypes.func.isRequired,

  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
}

export default ServiceProviderContent
